<template>
    <div class="input-box flexbox">
        <label class="label" for="name">{{title}}</label>
        <div class="ibox">
          <el-select :model-value="modelValue"
          @change="$emit('update:modelValue', $event)" 
          :placeholder="placeholder" 
          v-if="!isPreview">
            <el-option 
              v-for="(item,index) in options" 
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          <div class="input" v-if="isPreview">{{modelValue}}</div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'cselect',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: String,
    options:Array,
    title: String,
    placeholder:String,
    // value:String,
    isPreview:{type:Boolean,default:false}
  }
}
</script>

<style scoped>
.input-box{box-sizing: border-box; width:540px; margin-bottom:10px; line-height:36px; border: 1px solid #ccc; border-radius:5px;}
.input-box .flexbox{display: flex; align-items: center;}
.input-box .label{font-size:14px; color:#262628; font-weight:bold; padding:0 10px 0 15px;}
.input-box .ibox{flex:1 1 auto; height:36px; display:flex; align-items:center;}
.input-box .ibox .input[type='text']{flex:1 1 auto; height:26px; text-indent:5px; margin-right:15px; vertical-align: middle; border:none; outline:none; transition-duration: 300ms;}
.input-box .ibox:hover input[type='text']{background:#f9f9f9;}
.input-box .ibox .el-select{display:block; flex:1 1 auto;}
</style>
