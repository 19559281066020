<template>
	<div>
	<div class="table-title">岩板龙头企业资料填报表<span v-if="!loading" class="del-storage" @click="clearStorage">清空缓存</span></div>
  	<table border="1" class="table-order" ref="table">
      	<!-- <thead>
	        <tr>
	          <th colspan="6">低碳发展先进企业（工厂）资料填报表</th>
	        </tr>
      	</thead> -->
      	<tbody>
	      	<tr>
	      		<td>企业全称</td>
	      		<td colspan="2"><input @blur="input" type="" v-model="formdata.v0" /></td>
	      		<td>企业简称</td>
	      		<td colspan="2"><input @blur="input" type="" v-model="formdata.v1" /></td>
	      	</tr>
			<tr>
	      		<td colspan="1">岩板品牌名称</td>
	      		<td colspan="5"><input @blur="blur" type="" v-model="formdata.v2" /></td>
	      	</tr>
	      	<tr class="hasTextarea">
	      		<td>企业介绍<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="input($event,'v3')" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v3"></div>
	      			<!-- <textarea @blur="input" v-model="formdata.v" class="textarea" resize="none"></textarea> -->
	      		</td>
	      	</tr>

			<tr class="subtitle">
				<td colspan="6">
					<span>生产能力</span>
				</td>
			</tr>
			<tr>
	      		<td class="td1">岩板生产线数量</td>
	      		<td class="td2">
					<div class="inherit-input">
	      				<input @blur="input" type="" v-model="formdata.v4" />
	      				<span>条</span>
	      			</div>
				</td>
	      		<td class="td3">岩板日产量</td>
	      		<td class="td4">
					<div class="inherit-input">
	      				<input @blur="input" type="" v-model="formdata.v5" />
	      				<span> 万m²</span>
	      			</div>
				</td>
	      		<td class="td5">工厂所在地</td>
	      		<td class="td6"><input @blur="blur" type="" v-model="formdata.v6" /></td>
	      	</tr>
			<tr>
				<td>岩板厚度规格</td>
				<td colspan="5">
					<el-checkbox-group @change="change" v-model="checkList">
						<el-checkbox label="3mm"></el-checkbox>
						<el-checkbox label="6mm"></el-checkbox>
						<el-checkbox label="9mm"></el-checkbox>
						<el-checkbox label="12mm"></el-checkbox>
						<el-checkbox label="15mm"></el-checkbox>
						<el-checkbox label="20mm"></el-checkbox>
					</el-checkbox-group>
				</td>
			</tr>
			<tr class="hasTextarea">
	      		<td>每条生产线情况介绍（投产时间、产能及生产的产品）<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="input($event,'v7')" name="v7" @paste="paste" class="textarea" contenteditable="true"  v-html="formdata.v7"></div>
	      		</td>
	      	</tr>
			<tr>
	      		<td class="td1">岩板年度销额</td>
	      		<td class="td2" colspan="2">
					<div class="inherit-input">
						<span>2020年</span>
	      				<input @blur="input" type="" v-model="formdata.v8" />
	      				<span>万元</span>
	      			</div>
				</td>
	      		<td class="td3" colspan="2">
					  <div class="inherit-input">
						<span>2021年</span>
	      				<input @blur="input" type="" v-model="formdata.v9" />
	      				<span>万元</span>
	      			</div>
				</td>
	      		<td class="td4">
					<div class="inherit-input">
						<span>2022年</span>
	      				<input @blur="input" type="" v-model="formdata.v10" />
	      				<span> 万元</span>
	      			</div>
				</td>
	      	</tr>

			<tr class="subtitle">
				<td colspan="6">
					<span>产品工艺/创新/品质</span>
				</td>
			</tr>
			<tr>
	      		<td class="td1">新品发布数量</td>
	      		<td class="td2" colspan="2">
					<div class="inherit-input">
						<span>2020年</span>
	      				<input @blur="input" type="" v-model="formdata.v11" />
	      				<span>款</span>
	      			</div>
				</td>
	      		<td class="td3" colspan="2">
					<div class="inherit-input">
						<span>2021年</span>
	      				<input @blur="input" type="" v-model="formdata.v12" />
	      				<span>款</span>
	      			</div>
				</td>
	      		<td class="td4">
					<div class="inherit-input">
						<span>2022年</span>
	      				<input @blur="input" type="" v-model="formdata.v13" />
	      				<span> 款</span>
	      			</div>
				</td>
	      	</tr>
	      	<tr class="hasTextarea">
	      		<td>产品研发及<br/>创新工艺描述<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="input($event,'v14')" name="v14" @paste="paste" class="textarea" contenteditable="true"  v-html="formdata.v14"></div>
	      		</td>
	      	</tr>
			<tr>
	      		<td colspan="1">品质保障说明</td>
	      		<td colspan="5"><input @blur="input" type="" v-model="formdata.v15" /></td>
	      	</tr>
			

			<tr class="subtitle">
				<td colspan="6">
					<span>品牌推广/终端</span>
				</td>
			</tr>


	      	<tr class="hasTextarea">
	      		<td>品牌推广投入<br/>（2022年）</td>
	      		<td>
	      			<div class="inherit-input">
	      				<input @blur="input" type="" v-model="formdata.v16" />
	      				<span>元</span>
	      			</div>
	      		</td>
				<td>推广情况说明</td>
				<td colspan="3">
					<div @blur="input($event,'v17')" name="v17" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v17" placeholder="如参考、广告投放、渠道建设扶持等"></div>
				</td>
	      	</tr>

	      	<tr class="hasTextarea">
	      		<td>终端店面情况<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="input($event,'v18')" name="v18" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v18" placeholder="请列举终端店铺所在城市，附上上传店铺实景图"></div>
	      		</td>
	      	</tr>

	      	<tr class="hasTextarea">
	      		<td>交付能力<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="input($event,'v19')" name="v19" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v19" placeholder="请描述交付方面的做法"></div>
	      		</td>
	      	</tr>

			<tr class="hasTextarea">
	      		<td>未来计划<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="input($event,'v20','input')" name="v20" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v20" placeholder="请描述品牌发展方面的未来计划"></div>
	      		</td>
	      	</tr>

    	</tbody>
    </table>
	</div>
</template>

<script>
import { ref, reactive,onMounted,onUpdated, computed,toRaw} from 'vue'
import { ElMessageBox} from 'element-plus'


  export default{
    name: 'table5',
	props:{loading:{type:Boolean,default:false}},
    setup(props,{emit}){
    	let ctable4 = {};
    	if(!!localStorage.getItem('ctable4')){
    		ctable4 = JSON.parse(localStorage.getItem('ctable4'))
    	}else{
    		for(let i=0;i<15; i++){
    			ctable4['v'+i] = ''
    		}
    		console.log(ctable4);
    	}
    	const formdata = reactive(ctable4);

		let checkboxList = [];
		if(!!localStorage.getItem('checkList')){
    		checkboxList = JSON.parse(localStorage.getItem('checkList'))
    	}
		const checkList = ref(checkboxList);

    	const table = ref();
    	const input = (e,name)=>{
			// let nodeList = table.value.querySelectorAll('tr');
			// let sumHeight = 0;
			// for(let i=0; i<nodeList.length; i++){
			// 	let trHeight = window.getComputedStyle(nodeList[i]).height.replace("px","")*1;
			// 	sumHeight+=trHeight;
			// 	//textarea自适应高度
			// 	// if(nodeList[i].className==='hasTextarea'){
			// 	// 	let textarea = nodeList[i].querySelectorAll('.textarea')[0];
			// 	// 	textarea.style.height = "inherit";
			// 	// 	textarea.style.height = textarea.scrollHeight+'px';
			// 	// }
			// 	console.log(sumHeight)
			// }

			if(!!name){
				let targetElem = e.target;
				//删掉多余空行
				targetElem.innerHTML = targetElem.innerHTML.replace(/<div><br><\/div>/g, '');
				formdata[name] = targetElem.innerHTML;
				// console.log(targetElem.innerHTML)
			}
			
			localStorage.setItem("ctable4", JSON.stringify(toRaw(formdata)));
			// emit("dinput",toRaw(formdata));
    	}
    	const paste = (e)=>{
    		e.preventDefault();
	        var text = null;
	    
	        if(window.clipboardData && clipboardData.setData) {
	            // IE
	            text = window.clipboardData.getData('text');
	        } else {
	            text = (e.originalEvent || e).clipboardData.getData('text/plain') || prompt('在这里输入文本');
	        }
	        if (document.body.createTextRange) {    
	            if (document.selection) {
	                textRange = document.selection.createRange();
	            } else if (window.getSelection) {
	                sel = window.getSelection();
	                var range = sel.getRangeAt(0);
	                
	                // 创建临时元素，使得TextRange可以移动到正确的位置
	                var tempEl = document.createElement("span");
	                tempEl.innerHTML = "&#FEFF;";
	                range.deleteContents();
	                range.insertNode(tempEl);
	                textRange = document.body.createTextRange();
	                textRange.moveToElementText(tempEl);
	                tempEl.parentNode.removeChild(tempEl);
	            }
	            textRange.text = text;
	            textRange.collapse(false);
	            textRange.select();
	        } else {
	            // Chrome之类浏览器
	            document.execCommand("insertText", false, text);
	        }

    	}
		const clearStorage = ()=>{
			ElMessageBox.alert('是否清空表单缓存？', '清空缓存', {
				confirmButtonText: '确定',
				callback: (action) => {
					console.log(action);
					if(action==='confirm'){
						localStorage.removeItem("ctable4");
						localStorage.removeItem("checkList");
						Object.keys(formdata).forEach(function(key){
							formdata[key] = '';
						});
					}
				},
			})
		}
		const change = (e)=>{
			console.log(e);
			localStorage.setItem("checkList", JSON.stringify(e));
		}
    	return {
    		input,
    		table,
    		paste,
    		formdata,
			clearStorage,
			checkList,
			change
    	}
    }
  }
</script>

<style scoped>
.table-title{font-weight: bold;}
table{width: 100%; margin-top:5px; font-size:14px;}
table thead{background:#f6f6f6;}
table thead th{padding: 5px 5px;}
table .tr-title{font-weight: bold;}

table td{padding: 6px 8px; line-height:14px; position:relative;}
table td:nth-of-type(1){width:100px; line-height: 18px;}
table td:nth-of-type(3){width:160px; line-height: 18px;}

table td:nth-of-type(2n+1){text-align: center;}
table td input{width:100%; border:1px solid #ddd; padding:5px 5px; box-sizing: border-box;}
table td input:focus {outline:none; border: 1px solid #b70038;}
table td textarea{width:100%; border:1px solid #ddd; padding:5px 5px; box-sizing: border-box;}
table td textarea:focus {outline:none; border: 1px solid #b70038;}
table td.td1{width:120px;}
.textarea{width: 100%; min-height:100px; margin-left: auto;margin-right: auto;outline: 0;border: 1px solid #ddd;line-height: 22px;padding:5px 5px;word-wrap: break-word;overflow-x: hidden;overflow-y: auto;box-sizing: border-box; resize:none; overflow: hidden; transition: all 0.2s linear;}
.textarea:focus{outline:none; border: 1px solid #b70038;}


.inherit-input{text-align: center;}
.inherit-input input{width:60px; border:none; border-bottom:1px solid #666; text-align: center; margin:0 4px;}
.inherit-input input:focus{outline:none; border:none; border-bottom: 1px solid #b70038;}

.subtitle{font-weight: bold; color:#111; background:#f1f1f1;}
span.del-storage{color:#b70038; text-decoration: underline; cursor: pointer; margin-left:10px;}

.textarea:empty:before{
	content:attr(placeholder);
    font-size: 13px;
    color: #999;
	display: block; text-align:center; position:absolute; left:0; right:0; top:50%; margin-top:-9px;
}
.textarea:not(:empty):focus:before{content:none;}

</style>