<template>
  <div>
    <div class="title">{{detail.name}}</div>
    <div class="desp">
      <p>{{detail.desc}}</p>
      <p style="font-weight:blod;" v-if="detail.obj!==''"><span>评选对象：</span> {{detail.obj}}</p>
      <p style="font-weight:blod;"><span>评选标准：</span></p>
      <p v-for="(item,index) in detail.standards" :key="index">{{(1+index)+"、"+item}}</p>
      <table-desp :id="detail.id"></table-desp>
    </div>
    <div class="form-box">
        <el-form action="" :model="form" ref="form">
            <h2 class="t">2024新锐榜-{{detail.name}}</h2>

            <div v-for="(val,key) in detail.forms" :key="key">

              <c-input v-if="val.type==='text'" 
                v-show="!(form[val.name]==null&&isPreview)"
                :title="val.title" 
                :placeholder="val.placeholder"
                :name="val.name"
                :isPreview="isPreview"
                 v-model="form[val.name]">
              </c-input>
              
              <c-select v-if="val.type==='radio'" 
                v-show="!(form[val.name]==null&&isPreview)"
                :title="val.title" 
                :placeholder="val.placeholder" 
                :options="val.value" 
                :isPreview="isPreview"
                v-model="form[val.name]">
              </c-select>

              <c-base 
                :isPreview="isPreview"
                :formid="detail.id"
                v-if="baseIsShow&&val.name=='basicInformation'" 
                v-on:upload="uploadEnd($event,'basicInformation')" 
                :files ="form[val.name]">
              </c-base>

              <div class="big-box" v-if="val.type==='textarea'" v-show="!(form[val.name]==null&&isPreview)">
                <label class="label" for="name">{{val.title}}</label>
                <c-textarea 
                  :placeholder="val.placeholder" 
                  :name="val.name" 
                  v-model="form[val.name]" 
                  :isPreview="isPreview">
                </c-textarea>
                <c-upload v-if="!!val.files" 
                  :isPreview="isPreview"
                  v-on:upload="uploadEnd($event,val.files.name)"
                  :files = "form[val.files.name]"
                  :files_path = "form[val.files.name+'_path']"
                  :title="val.files.title" 
                  :size="val['data-size']*1"
                  :nums="2"
                  placeholder="">
                </c-upload>
              </div>

              <div class="big-box" v-if="val.type==='file'&&val.accept==='image/*'&&val.name!='basicInformation'" v-show="!(form[val.name]==null&&isPreview)">
                <label class="label" for="name">
                  {{val.title}}
                  <!-- <a href="https://17thxrb.fstcb.com/files/%E5%8A%9F%E8%83%BD%E6%80%A7%E7%93%B7%E7%A0%96%E8%A1%A5%E5%85%85%E8%B5%84%E6%96%99.docx" target="_bland" class="download-a" v-if="detail.id===23&&val.name==='testReport'">点击下载“功能性说明表格”</a> -->
                </label>
                <c-upload 
                  :isPreview="isPreview"
                  :title="!!val.buttonTitle?val.buttonTitle:'上传图片'" 
                  v-on:upload="uploadEnd($event,val.name)"
                  :files = "form[val.name]"
                  :files_path = "form[val.name+'_path']"
                  :size="val['data-size']*1"
                  :nums="val['data-pics']*1"
                  :placeholder="val.placeholder">
                </c-upload>
              </div>

              <div class="big-box" v-if="val.type==='file'&&val.accept!=='image/*'" v-show="!(form[val.name]==null&&isPreview)">
                <label class="label label-flex" for="name">
                  <div>{{val.title}}</div>
                  <div class="downloadbox"><a :href="'https://20thxrb.fstcb.com/document/'+detail.documentUrl" :download="detail.documentUrl" >点击下载 <span>{{ detail.documentUrl }}</span></a></div>
                </label>
                <c-upload 
                  :isPreview="isPreview"
                  title="上传报名表" 
                  v-on:upload="uploadEnd($event,val.name)"
                  :files = "form[val.name]"
                  :files_path = "form[val.name+'_path']"
                  type="file"
                  :size="val['data-size']*1"
                  :nums="val['data-pics']*1"
                  :placeholder="val.placeholder">
                </c-upload>
              </div>

            </div>

            <div class="btnbox">
              <!-- <div class="btn">保存到草稿箱</div> -->
              <div class="btn bgred" v-if="isPreview" @click="edit">编 辑</div>
              <!-- <div class="btn" v-if="!isPreview" @click="checkForm(1)">保存到草稿箱</div> -->
              <div class="btn bgred" v-if="!isPreview&&d===1" @click="checkForm(1)">更 新</div>

              <div class="btn" v-if="!isPreview&&d===0" @click="checkForm(0)">保存到草稿箱</div>
              <div class="btn bgred" v-if="!isPreview&d===0" @click="checkForm(1)">提 交</div>
              <div class="btn bgred" @click="back()">返回报名页面</div>


            </div>

        </el-form>
    </div>

  </div>
</template>

<script>
import cInput from './components/c-input.vue';
import cSelect from './components/c-select.vue';
import cTextarea from './components/c-textarea.vue';
import cUpload from './components/c-upload.vue';
import cBase from './components/c-base.vue';
import tableDesp from './components/table/table-desp.vue';
import store from '@/store/index.js';



import {updatePrizes,getPrizesDetail} from '@/api/index.js';


export default {
  name: 'f',
  components: {cInput,cSelect,cTextarea,cUpload,cBase,tableDesp},
  data(){
    return {
      form:{},
      listId:this.$route.query.listid, //列表id
      d:this.$route.query.d*1,  //判断是否为草稿，草稿d===1
      id:this.$route.query.id, //报名表id
      // award_id:
      prize_id:this.$route.query.prid,  //奖项id
      detail:{name:'',desc:'',obj:''},
      isPreview:!!(this.$route.query.isp*1),
    }
  },
  created(){
    console.log(this.$route);
    this.$store.commit('setPrizesIdx',this.$route.query.idx);
    // this.getDetail();
    console.log(this.token);
  },
  computed:{
    splitFiles(){
      // return str=>{
      //   if(!str){
      //     return [];
      //   }else{
      //     return str.split(',');
      //   }
      // }
      return str=>{
        return str;
      }
    },
    token(){
      let token = this.$store.state.token;
      console.log(token)
      if(token!==undefined){
        return this.$store.state.token
      }else{
        this.$store.commit('setLoginShow',true);
        return undefined;
      }
    },
    baseIsShow(){
      let id = this.detail.id;
      return id===18||id===19||id===20||id===21||id===33||id===34||id===35||id===36;
    },
    detail(){
      let detail = store.getters.formDetail;
      console.log(detail)
      if(detail===undefined){
        detail=JSON.parse(localStorage.detail);
      }else{
        localStorage.detail = JSON.stringify(detail);
      }
      this.createFormData(detail)
      return detail;
    },
  },
  methods:{
    createFormData(detail){
      let form = {};
      let formdata = JSON.parse(localStorage.getItem("formdata")||{})

      for(let key in detail.forms){
        console.log("key:",key);
        console.log("value:",detail.forms[key]);

        form[detail.forms[key].name]='';
        if(!!detail.forms[key].files&&detail.forms[key].type==="textarea"){
          form[detail.forms[key].files.name]='';
        }
      }
      Object.assign(form,formdata)
      this.form = form;
      
      console.log(this.form);
    },
    uploadRemove(e,name){
      console.log(e);
      console.log(name);
    },
    uploadEnd(e,name){
      console.log(e);
      console.log(name);
      let str = '';
      e.forEach((item,index)=>{
        if(index!==0){
          str+=',';
        }
        str+=item;
      })
      this.form[name]=str;
    },
    checkForm(draft) {
      if(this.token===undefined){
        this.$store.commit('setLoginShow',true);
        return false;
      }
      console.log(this.form);
      //保存草稿
      if(draft===0){
        this.submitForm(draft);
        return false;
      }

      let keys = Object.keys(this.form);
      for(let i=0; i<keys.length; i++){
        let val = this.form[keys[i]];
        // console.log(this.detail.forms[keys[i]]);
        //第一层表单项
        if(!val&&this.detail.forms[keys[i]]!=undefined){
          // console.log("1级表单校验");
          alert(this.detail.forms[keys[i]].title+"不能为空!");
          return false;
        }

        //第二层表单项校验，例如重大事件表单下有二级表单数据
        // if(this.detail.forms[keys[i]]!=undefined&&this.detail.forms[keys[i]].files!=undefined){
        //   console.log(this.detail.forms[keys[i]].files);
        //   if(this.form[this.detail.forms[keys[i]].files.name]===''){
        //     // alert(this.detail.forms[keys[i]].files.title+"不能为空!");
        //     alert("请上传相关证明文件！");
        //     return false;
        //   }
        // }

      }
      
      this.submitForm(draft);
    },
    submitForm(draft){

      let copyForm = Object.assign({},this.form);
      for(let key in copyForm){
        if(copyForm[key] instanceof Array){
          copyForm[key] = copyForm[key].join(",")
        }
      }

      Object.assign(copyForm,{token:this.token,status:draft})

      updatePrizes(copyForm).then(res=>{
        console.log(res);
        if(res.errcode===1){
          this.$message.error(res.errmsg);
        }else if(res.errcode===200){
          this.$message.success(res.errmsg);
          
          this.$router.push({ path: '/record' })
        }
      })

    },
    edit(){
      this.isPreview = false;
    },
    back(){
      this.$router.push({ path: '/index' })
    }
  }
}
</script>

<style scoped>
.title{padding:0 25px; font-size: 25px; color:#ecbf95; margin:50px 0 15px 0;}
.desp{padding:0 25px; line-height:24px; font-size:14px; color:#ecbf95;}
.desp span{color:#edb788; opacity:.9;}

.form-box{margin-top:30px; padding:70px 64px; background:#1f256c;}
.form-box>form{padding:20px 130px; background:#fff;}
.form-box>form  h2{font-size:20px; margin-top:10px; margin-bottom:30px; color:#28282a; text-align:center;}

/*.el-form{border:4px solid #eda95e;}*/

.big-box{box-sizing: border-box; width:460px; margin-bottom:10px; line-height:36px; border: 1px solid #ccc; border-radius:5px;}
.big-box .label{line-height:36px; border-bottom:1px solid #ccc; display: block; margin:0 15px 15px 15px; font-size:14px; color:#28282a; font-weight:bold; }
.big-box .label.label-flex{display:flex;}
.big-box .label.label-flex .downloadbox{margin-left:10px;}
.big-box .label.label-flex .downloadbox a{color:#999; }
.big-box .label.label-flex .downloadbox span{color:#cc0526; text-decoration: underline;}


.btnbox{display: flex; justify-content: center; margin-top:20px;}
.btn{width:120px; margin:0 10px; height:37px; line-height:37px; text-align:center; border:1px solid #240085; color:#fff; font-size:14px; border-radius:5px; cursor:pointer; background: #17213a; transition-duration: 300ms;}
.btn.bgred{background:#b50333; border:1px solid #b50333;}
.btn:hover{opacity:.7; box-shadow: 1px 1px 3px #999;}
.download-a{font-weight: normal; text-decoration: underline; color:red; margin-left:5px;}
</style>

<style>
/*element ui样式*/
.form-box .el-input__inner{height:36px!important; line-height:36px; border:none; outline:none;}
.form-box .el-input__icon{line-height: 36px;}
input::-webkit-input-placeholder{color:#999;}
input::-moz-input-placeholder{color:#999;}
textarea::-webkit-input-placeholder{color:#999;}
textarea::-moz-input-placeholder{color:#999;}
</style>