const follow_name = {"title": "对接人","name": "follow_name","type": "radio","required": "required", "placeholder": "请选择对接人", "value": ["杨美花","霍铭炎","曾晓燕","李婉莹","徐达华","李可飞","程欣", "其他"],}
const company = {"title": "公司全称","name": "company","type": "text","required": "required","maxlength": 100,"placeholder": "请填写公司全称","value": ""};

const basics_file = {"title": "报名表格","name": "basics_file","type": "file","required": "required","multiple": false,"accept": "application/msword","data-pics": 1,"placeholder": "请下载上方报名表模板，填写完整后上传","buttonTitle": ""};
const logo_image = {"title": "品牌LOGO","name": "logo_image","type": "file","required": "required","multiple": false,"accept": "image/*","data-pics": 10,"placeholder": "上传品牌 logo 1 张，格式 JPG/PNG/GIF 均可，大小不超过 5M，图片宽度不低于 800 像素","buttonTitle": ""}

const correlations_image = {"title": "相关图片","name": "correlations_image","type": "file","required": "required","multiple": false,"accept": "image/*","data-pics": 10,"placeholder": "上传品牌形象图片、企业大楼形象或车间装备照片3张，每张图不超过5M","buttonTitle": ""}

const patent_certificate = {"title": "专利证书","name": "patent_certificate","type": "file","required": "required","multiple": false,"accept": "image/*","data-pics": 10,"placeholder": "上传专利证书，每张图不超过5M","buttonTitle": ""}
const trademark_documents = {"title": "商标文件", "name": "trademark_documents","type": "file","required": "required","multiple": false,"accept": "image/*","data-pics": 10,"placeholder": "上传商标注册相关文件复印件或者照片，大小不超5M","buttonTitle": ""}
const certificate_image = {"title": "相关证书", "name": "certificate_image","type": "file","required": "required","multiple": false,"accept": "image/*","data-pics": 10,"placeholder": "上传相关证书，大小不超5M","buttonTitle": ""}
const testing_certificate = {"title": "质量检测证明", "name": "testing_certificate","type": "file","required": "required","multiple": false,"accept": "image/*","data-pics": 10,"placeholder": "上传质量检测证明相关照片，大小不超5M","buttonTitle": ""}
const business_license = {"title": "营业执照","name": "business_license","type": "file","required": "required","multiple": false,"accept": "image/*","data-pics": 10,"placeholder": "上传企业营业执照复印件或照片，大小不超5M","buttonTitle": ""}
const report_image = {"title": "相关报告", "name": "report_image","type": "file","required": "required","multiple": false,"accept": "image/*","data-pics": 10,"placeholder": "上传政府或第三方对工厂能耗等数据进行检测或认证的有关报告，大小不超过 5M","buttonTitle": ""}
const store_image = {"title": "终端店面图片", "name": "store_image","type": "file","required": "required","multiple": false,"accept": "image/*","data-pics": 10,"placeholder": "上传终端店面实景照片，每张图不超过5M","buttonTitle": ""}
const promotion_image = {"title": "品牌推广照片", "name": "promotion_image","type": "file","required": "required","multiple": false,"accept": "image/*","data-pics": 10,"placeholder": "上传如广告投放照片、参展照片等","buttonTitle": ""}

//,"placeholder":""
// correlations_image:Object.assign({},correlations_image,{"placeholder":"上传相关证书、照片等资料"})
// :Object.assign({},,{"placeholder":""})
export const prizeList = [
    {   
        id:1, 
        name:"金土奖",
        obj:"",
        standards:["锐意创新，煅土成金”的伟大笃行者，对推动行业发展具有里程碑意义的个人或企业；"],
        documentUrl:"1、新锐榜-金土奖报名表.docx",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传相关证书、照片等资料"})
        },
    },
    {   
        id:2, 
        name:"创新引领者",
        obj:"",
        documentUrl:"2、新锐榜-创新领跑者报名表.docx",
        standards:["成立时间10年以上，企业年销售额在10亿元以上；","近五年（2018年—2023年），取得国家发明专利15项以上（非外观专利、非实用新型专利），以国家知识产权局授权公开日期为准；","企业参与国家级标准和行业标准的制定不少于10项；","坚守匠心品质，在产品创新研发、产品生产等领域不断突破，推出过行业首创技术或产品，引领行业发展；","自有研发中心或实验室，研发人员水平较高，每年有固定的研发投入；","具体评分标准见下表；"],
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            patent_certificate:Object.assign({},patent_certificate,{"placeholder":"上传企业获得的国家级发明专利证书等，每张大小不超 5M"}),
            trademark_documents,
            certificate_image:  Object.assign({},certificate_image,{"title":"标准证明","placeholder":"上传企业参与制定的标准证明文件等，每张大小不超 5M"}),
            correlations_image,
        },
    },
    {   
        id:3, 
        name:"数智化标杆企业",
        documentUrl:"3、新锐榜-数智化标杆企业报名表.docx",
        standards:["企业在行业内有较高知名度，具有较好的经营管理、技术研发和融合创新能力；","企业利用大数据、人工智能、云计算、5G通讯等创新技术开展数智化建设，并取得一定成果；","企业在生产、营销、供应链、人力资源、财务等领域借力数智化取得成效；","企业数智化走在行业前列，案例具有推动行业数智化示范意义；","具体评分标准见下表；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image: Object.assign({},certificate_image,{"title":"数智化建设证明","placeholder":"上传数智化建设相关的车间照片等，大小不超 5M"}),
            correlations_image,
        },
    },
    {   
        id:4, 
        name:"科技创新奖（技术或产品）",
        documentUrl:"4、新锐榜-科技创新奖报名表.docx",
        standards:["在研发生产上取得突破性的技术，或所生产的产品应用了独自研发的领先技术；","技术或者产品拥有国家颁发的专利证书；","技术或者产品在陶瓷卫浴产业科技领域有重大突破；","技术或产品对中国陶瓷卫浴产业的发展有突出贡献。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image: Object.assign({},certificate_image,{"title":"证明文件","placeholder":"上传产品或技术专利证书等证明文件，每张图不超过5M"}),
            correlations_image
        },
    },
    {   
        id:5,
        name:"服务质量奖金奖/优秀奖",
        documentUrl:"5、新锐榜-服务质量奖报名表.docx",
        standards:["坚持以人为本的服务宗旨为客户提供服务；","建立了标准化的服务流程及相关制度，且制度实施时间不少于3年；","建立完善的服务管理保障体系，设立有服务质量监督部门；","有创新性的服务举措。紧扣市场需求的变化，针对客户购买方式变化、服务体验要求的提高等，有创新性的服务举措；","在业内和终端有良好口碑，近五年内在公共媒体平台上没有因为与客户、合作伙伴等发生重大纠纷而产生重大负面影响。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"证明文件","placeholder":"上传服务相关的图片或客户好评证明图片3张，精度要求300dpi，每张图不超过5M"})
        },
    },
    {   
        id:6,
        name:"年度产品—瓷砖类 金奖/优秀奖",
        documentUrl:"6、新锐榜-年度产品（瓷砖类）报名表.docx",
        standards:["产品工艺自主创新，运用了先进技术；","产品外观设计独特，花色、纹理有较强的识别性，包装设计新颖美观；","产品在丰富陶瓷文化、拓宽陶瓷运用领域等方面表现突出；","产品市场增长率、销售总量、创造利润等方面表现突出。","要求是2023年12月31日之前上市、并且已有销售记录的产品。"],
        obj:"大理石瓷砖、仿古砖、户外砖、木纹砖、综合类",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:7,
        name:"年度产品—卫浴类 金奖/优秀奖",
        documentUrl:"7、新锐榜-年度产品（卫浴类）报名表.docx",
        standards:["产品工艺自主创新，运用了先进技术；","产品外观设计独特，造型、材质有较强的识别性，包装设计新颖美观；","产品在丰富陶瓷文化、拓宽陶瓷运用领域等方面表现突出；","产品市场增长率、销售总量、创造利润等方面表现突出。","要求是2023年12月31日之前上市，并且已有销售记录的产品。"],
        obj:"智能马桶、马桶、浴室柜、花洒、龙头、其他类",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:8,
        name:"年度产品—装备材料 金奖/优秀奖",
        documentUrl:"8、新锐榜-年度产品（装备材料类）报名表.docx",
        standards:["产品是否自主创新，拥有相关专利证书；","产品是否能促进陶瓷行业形成新工艺、新产品或新模式；","产品是否获得客户广泛认可，在销售总量、创造利润等方面表现突出；","产品是否具有示范引领作用，是否有助于推动陶瓷行业高质量发展。","要求是2023年12月31日之前上市、并且已有销售记录的产品。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:9,
        name:"年度产品—其他类别 金奖/优秀奖",
        documentUrl:"9、新锐榜-年度产品（其他类别）报名表.docx",
        standards:["产品工艺自主创新，运用了先进技术；","产品外观设计独特，花色、纹理有较强的识别性，包装设计新颖美观；","产品在丰富陶瓷文化、拓宽陶瓷运用领域等方面表现突出；","产品市场增长率、销售总量、创造利润等方面表现突出。","要求是2023年12月31日之前上市、并且已有销售记录的产品。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:10,
        name:"陶瓷领军品牌",
        documentUrl:"1、新锐榜-陶瓷领军品牌报名表.docx",
        standards:["品牌注册时间超过10年，在行业和市场终端具有较高知名度，没有重大负面消息；","销售区域覆盖全国主要城市，终端销售网点不少于1000个；","市场占有率、销售额（超过10亿元）等方面表现突出；","品牌所主导的事件，对推动整个行业的发展、进步具有重要意义，具有行业引领作用。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            trademark_documents, //商标文件
            business_license, //营业执照
            correlations_image,
        },
    },
    {   
        id:11,
        name:"卫浴领军品牌",
        documentUrl:"2、新锐榜-卫浴领军品牌报名表.docx",
        standards:["成立时间超过10年，在行业和市场终端具有较高知名度，没有重大负面消息；","销售区域覆盖全国主要城市，终端销售网点不少于1000个；","市场占有率、销售额（超过10亿元）度等方面表现突出；","4、品牌所主导的事件，对推动整个行业的发展、进步具有重要意义；具有行业引领作用。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            trademark_documents, //商标文件
            business_license, //营业执照
            correlations_image,
        },
    },
    {   
        id:12,
        name:"低碳先锋品牌",
        documentUrl:"3、新锐榜-低碳先锋品牌报名表.docx",
        standards:["将低碳发展纳入企业发展战略，并致力用更低单位成本的碳排放量创造更大效益。","采取多举措降低能源的消耗，节能降耗效果明显；","生产过程中固废排放必须符合环保标准；近5年没有因为环保问题被处罚；","企业入选工信部“绿色制造/绿色工厂”公示名单或有其它相关认证；","单位产品能耗水平达到能耗限额国家标准的先进值，且为行业领先水平；（参考 GB21252-2013 建筑卫生陶瓷单位产品能源消耗限额）","具体评分标准见下表（以一个具体工厂为代表，填写数据申报）"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:Object.assign({},certificate_image,{"placeholder":"上传“绿色制造/绿色工厂”证书或有其它相关认证证书，大小不超过 5M"}),//相关证书
            report_image, //相关报告
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传企业大楼形象或车间装备照片 3 张，每张图不超过5M"}),
        },
    },
    {   
        id:13,
        name:"年度影响力品牌 金奖/优秀奖",
        documentUrl:"4、新锐榜-年度影响力品牌报名表.docx",
        standards:["品牌形象鲜明，受众定位准确，自成体系；","2023年发展良好，在市场占有率、销售总量、利润贡献度等方面表现突出；","2023年有突出的营销动作或品牌事件，获得业界好评和媒体关注。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            trademark_documents, //商标文件
            business_license, //营业执照
            correlations_image,
        },
    },
    {   
        id:14,
        name:"新锐品牌 金奖/优秀奖",
        documentUrl:"5、新锐榜-新锐品牌报名表.docx",
        standards:["品牌体系独特，具有美学和潮流指向意义；","品牌形象鲜明，受众定位准确，自成体系；","品牌成长快速，近年在产品开发、市场增长、利润贡献度等方面表现突出；","品牌传播力强，在传播方面有令人瞩目、卓有成效的举措，获得业界好评和媒体关注；","2018年1月1日后诞生的品牌，以获得注册证书时间为准。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            trademark_documents, //商标文件
            business_license, //营业执照
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传企业大楼形象或车间装备照片 3 张，每张图不超过5M"}),
        },
    },
    {   
        id:15,
        name:"设计师喜爱品牌",
        documentUrl:"6、新锐榜-设计师喜爱品牌报名表.docx",
        standards:["品牌形象鲜明，受众定位准确，坚持以独特而前沿的设计引领品牌发展；","结合消费趋势，深挖产品色彩、质感、纹理、造型、风格等，为设计赋予更多的可能，并且有代表性产品；","品牌深受设计师群体欢迎，产品在设计渠道销售增长率、销售总量、创造利润等方面表现突出；","4、必须有三个以上（含三个）主流设计师或设计机构的合作合同（或推荐书）。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"证明材料","placeholder":"上传三个设计机构/设计师合作合同（合同封面即可），或者设计机构/设计师推荐表"}),
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传品牌形象、产品空间等照片 3 张，每张图不超过 5M"}),
        },
    },
    {   
        id:16,
        name:"空间定制先锋品牌",
        documentUrl:"7、新锐榜-空间定制先锋品牌报名表.docx",
        standards:["率先探索定制业务，整合产品，为客户提供住宅空间、公寓空间、样板房空间、厨卫空间等空间定制服务或产品定制服务；","企业建立前后端一体的柔性定制化生产系统，能够提供从上门量尺、方案设计、产品组合、配送安装等一站式服务；","涉足定制业务三年以上，并且定制业务覆盖终端多个网点；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传品牌形象、产品空间等照片 3 张，每张图不超过 5M"})
        },
    },
    {   
        id:17,
        name:"智能卫浴标杆品牌",
        documentUrl:"8、新锐榜-智能卫浴标杆品牌报名表.docx",
        standards:["成立时间超过10年，具有独立研发、生产智能卫浴产品的实力；","智能产品年销量超过20万件；","产品品质过硬，近三年国抽没有重大产品质量问题；","掌握核心技术，研发的技术或者产品功能对中国智能卫浴发展、提升产品质量有推动作用；","参与行业标准或国家标准的起草，对规范智能卫浴发展有突出贡献；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传品牌形象、主推产品等照片 3 张，每张图不超过 5M"}),
            trademark_documents, //商标文件
            certificate_image:  Object.assign({},certificate_image,{"title":"证明文件","placeholder":"上传企业技术专利证书，质量检测相关证明等"})
        },
    },
    {   
        id:18,
        name:"家居焕新推荐品牌",
        documentUrl:"9、新锐榜-家居焕新推荐品牌报名表.docx",
        standards:["企业产品质量稳定，产品品类和款式丰富，能满足家居焕新的不同场景需求。","有完善的服务标准和服务流程，并且针对家居焕新的需求，有创新性的服务举措。","终端网点覆盖范围大，对不同地区用户的焕新需求能及时响应。","结合焕新的消费趋势和消费需求，推出过更适配的产品和生活方案。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            trademark_documents, //商标文件
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传品牌在家居焕新方面的行动等照片 3 张，每张图不超过 5M"}),
        },
    },
    {   
        id:19,
        name:"低碳发展先进企业",
        documentUrl:"1、新锐榜-低碳发展先进企业报名表.docx",
        standards:["将低碳发展纳入企业发展战略，并致力用更低单位成本的碳排放量创造更大效益。","采取多举措降低能源的消耗，节能降耗效果明显；","生产过程中固废排放必须符合环保标准；近5年没有因为环保问题被处罚；","企业入选工信部“绿色制造/绿色工厂”公示名单或有其它相关认证；","单位产品能耗水平达到能耗限额国家标准的先进值，且为行业领先水平；（参考 GB21252-2013 建筑卫生陶瓷单位产品能源消耗限额）","具体评分标准见下表（以一个具体工厂为代表，填写数据申报）"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:Object.assign({},certificate_image,{"placeholder":"上传“绿色制造/绿色工厂”证书或有其它相关认证证书，大小不超过 5M"}),//相关证书
            report_image, //相关报告
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传企业大楼形象或车间装备照片 3 张，每张图不超过5M"}),
        },
    },
    {   
        id:20,
        name:"产品力领先企业",
        documentUrl:"2、新锐榜-产品力领先企业报名表.docx",
        standards:["坚守匠心品质，在产品研发、产品生产制造、产品应用等领域不断突破，引领行业发展；","近三年连续推出新产品或新技术，相比同类别的企业，所推出的产品或技术处于领先地位；","代表性产品的销售比超过20%，即推出的代表性产品在所有产品的销售额中，占据的比例超过20%；","自有研发中心或实验室，研发人员水平较高；","产品品质过硬，近五年国抽没有重大产品质量问题；","具体评分标准见下表；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            patent_certificate:Object.assign({},patent_certificate,{"placeholder":"上传企业获得的国家级或省级重要的技术专利证书等，大小不超 5M"}), //专利证书
            report_image:Object.assign({},report_image,{"placeholder":"上传政府或第三方对产品质量检测或技术认证的有关报告，大小不超过 5M"}), //相关报告
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传企业大楼形象或车间装备照片 3 张，每张图不超过5M"}),
        },
    },
    {   
        id:21,
        name:"制造力领先企业",
        documentUrl:"3、新锐榜-制造力领先企业报名表.docx",
        standards:["企业自有生产工厂，且长期专注于产品的研发生产，生产技术或制造工艺较高，关键性能指标处于国内同类产品领先水平；","产品生产工艺自主创新，运用了先进技术，提升产品品质，并在保障产品质量上有突出举措；","严格执行国家质量标准，近十年国抽没有重大产品质量问题，且产品质量在行业内或终端市场上有良好的口碑，投诉率低；","生产瓷质砖要求吸水率≤0.3%；","产品市场增长率、销售总量、创造利润等方面表现突出，销售规模不低于10亿元；","具体的评分标准见下表："],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传企业大楼形象或车间装备照片 3 张，每张图不超过5M"}),
            report_image:Object.assign({},report_image,{"placeholder":"上传政府或第三方对产品质量检测或技术认证的有关报告，大小不超过 5M"}), //相关报告
        },
    },
    {   
        id:22,
        name:"岩板龙头企业",
        documentUrl:"4、新锐榜-岩板龙头企业报名表.docx",
        standards:["涉足岩板领域时间超过3年；","有能够生产1600×3200mm或以上规格的大岩板生产线，日产能不少于1.5万平方米；","成长快速，在市场增长率、产品销量等方面表现突出；","产品质量稳定，所生产/销售的岩板产品没有发生重大质量问题；","自主运营岩板品牌，独立建终端店面；可为消费者提供交付服务","具体的评分标准见下表："],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"证明文件","placeholder":"上传如获得国家级、省市级相关认定，终端客户应用证明文件等"}),
            store_image, //终端店面图片
            promotion_image, //品牌推广照片
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传企业大楼形象或车间照片 3 张，每张图不超过5M"}),
        },
    },
    {   
        id:23,
        name:"区域龙头企业",
        documentUrl:"5、新锐榜-区域龙头企业报名表.docx",
        standards:["企业综合实力在当地排名靠前，固定资产在5亿元以上；","企业生产规模在当地排名靠前；","企业诚信经营，不偷税漏，税务评级在B级以上；","企业在科技创新上有重要成就，获得多项技术专利，对推动行业进步有积极作用；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"证明文件","placeholder":"上传包括专利技术证书、税收证明、诚信经营证书、各类奖项等"}),
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传品牌形象、生产车间等照片 3 张，每张图不超过 5M"}),
        },
    },
    {   
        id:24,
        name:"社会责任企业",
        documentUrl:"6、新锐榜-社会责任企业报名表.docx",
        standards:["遵守法律，诚实守信，合法缴纳税收，没有重大违法违纪行为；","保障企业员工、合作伙伴和客户的正当权益；","有良好的企业文化，为员工的职业发展、身心健康等各个方面提供保障；","积极参与公益活动，在扶贫、文化教育、环境保护、公共卫生、应急事件等领域有突出贡献；","积极推动陶瓷文化传播。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传反映上述相关社会责任事件的3 张，每张图不超过5M"}),
        },
    },
    {   
        id:25,
        name:"品质金奖",
        documentUrl:"1、新锐榜-品质金奖报名表.docx",
        standards:["生产工艺自主创新，运用了先进技术，提升产品品质，并在保障产品质量上有突出举措；","产品质量在行业内或终端市场上有良好的口碑，投诉率低，近十年国抽没有重大产品质量问题；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            testing_certificate:  Object.assign({},testing_certificate,{"title":"产品检测报告","placeholder":"上传产品检测报告（必须有）"}),
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图片、应用效果图2 张，每张图不超过 5M"}),
        },
    },
    {   
        id:26,
        name:"国潮精品",
        documentUrl:"2、新锐榜-国潮精品报名表.docx",
        standards:["1、产品工艺、技术自主创新，产品质量稳定；","将中国特色文化元素，结合现代潮流元素进行创作设计。","产品在丰富陶瓷文化、拓宽陶瓷运用领域等方面表现突出；","产品市场增长率、销售总量、创造利润等方面表现突出。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:27,
        name:"年度精品",
        documentUrl:"3、新锐榜-年度精品报名表.docx",
        standards:["产品工艺、技术自主创新，产品质量稳定；","将中国特色文化元素，结合现代潮流元素进行创作设计。","产品在丰富陶瓷文化、拓宽陶瓷运用领域等方面表现突出；","产品市场增长率、销售总量、创造利润等方面表现突出。","要求是2023年10月1日之后推出的新品"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:28,
        name:"原创设计产品 金奖/优秀奖",
        documentUrl:"4、新锐榜-原创设计产品报名表.docx",
        standards:["产品设计理念原创，能够赋予产品独特的内涵和风格特点；","产品花色、图案等设计元素原创，不能单一来源于仿生类产品（例如仿石、仿木、仿布等仿大自然类）；","能够从文化、艺术领域挖掘创作理念、提炼创作元素，并将它们其融入设计中，表现陶瓷之美；","品设计独特，具有美学或潮流指向意义；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            patent_certificate: Object.assign({},patent_certificate,{"title":"专利或版权证书","placeholder":"上传产品专利证书、版权登记证明、获得的设计奖项等证明文件"}),
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:29,
        name:"技术工艺领先产品",
        documentUrl:"5、新锐榜-技术工艺领先产品报名表（陶瓷卫浴企业）.docx",
        standards:["近3年该产品获得相关认定或鉴定（如，国际领先、国家发明专利、实用新型专利等）","产品运用了先进技术或工艺创新，与同类产品相比有明显的优势；","3、产品有望成为行业主流产品，推动产品升级换代；","产品市场增长、销售总量、创造利润等方面表现突出；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"相关证明","placeholder":"上传产品专利证书和获得的奖项等证明文件"}),
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:30,
        name:"质感美学奖金奖/优秀奖",
        documentUrl:"6、新锐榜-质感美学奖报名表.docx",
        standards:["产品质感独树一帜，区别于市场同类产品，能给人留下深刻印象；","产品具有高水平的美学品位，能充分体现空间风格特色；","产品具有独特的原创设计理念或艺术表现形式；","产品运用了独特的工艺、技术或材料。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:31,
        name:"抗菌卫浴产品（金/奖）",
        documentUrl:"7、新锐榜-抗菌卫浴产品报名表.docx",
        standards:["凭借领先的技术和品质，打造出色的卫浴产品，以满足消费者日益增长的健康生活的需求；","产品的生产采用了抗菌、除菌材料，能够有效地抑制病菌在卫浴产品表面上的滋生繁殖；","产品采用了抗菌、除菌的技术，对卫浴空间的病原微生物、病菌等有害物质的传播具有明显的阻断作用；","其他卫浴产品的抗菌性能应当符合相关的标准；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            testing_certificate:  Object.assign({},testing_certificate,{"title":"检测报告","placeholder":"上传关于产品抗菌、除菌功能的检测报告，或抗菌认证报告"}),
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:32,
        name:"岩板金奖/岩板优秀奖",
        documentUrl:"8、新锐榜-岩板金奖、岩板优秀奖报名表.docx",
        standards:["产品工艺自主创新，运用了先进技术；","产品外观设计独特，花色、纹理有较强的识别性，包装设计新颖美观；","产品在丰富陶瓷文化、拓宽陶瓷运用领域等方面表现突出；","产品市场增长率、销售总量、创造利润等方面表现突出","单片面积不低于 1.6平方米。","产品被应用，而且有实际应用案例。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:33,
        name:"设计师喜爱产品",
        documentUrl:"9、新锐榜-设计师喜爱产品报名表.docx",
        standards:["产品工艺自主创新，运用行业先进技术；","产品在设计渠道销售增长率、销售总量、创造利润等方面表现突出；","产品具有独特的原创设计理念或艺术表现形式；","产品应用过程中深受设计师群体的好评，必须有三个以上（含三个）主流设计师的应用案例、合作合同或推荐书。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"设计应用证明","placeholder":"上传三个设计机构/设计师合作合同（合同封面即可），或者设计机构/设计师推荐表"}),
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:34,
        name:"工程用户喜爱产品",
        documentUrl:"10、新锐榜-工程用户喜爱产品报名表.docx",
        standards:["产品工艺自主创新，运用行业先进技术；","产品在工程渠道销售增长率、销售总量、创造利润等方面表现突出；","产品工程应用特征突出；","产品在应用过程中深受工程用户的好评，必须提供重点工程用户证明。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            correlations_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"工程应用证明","placeholder":"上传重点工程用户证明，每张图不超过 5M"}),
            testing_certificate //质量检测证明
        },
    },
    {   
        id:35,
        name:"家装用户喜爱产品",
        documentUrl:"11、新锐榜-家装用户喜爱产品报名表.docx",
        standards:["产品工艺自主创新，运用行业先进技术；","产品在家装渠道销售增长率、销售总量、创造利润等方面表现突出；","产品能充分体现家居空间风格特色；","产品在应用过程中深受消费者群体的好评。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，家装应用图 2 张，精度要求 300dpi，每张图不超过5M"}),
            // certificate_image:  Object.assign({},certificate_image,{"title":"工程应用证明"}),
        },
    },
    {   
        id:36,
        name:"品牌展厅 金奖/优秀奖",
        documentUrl:"1、新锐榜-品牌展厅报名表.docx",
        standards:["展厅定位清晰，在整体布局和设计具有独到之处；","具有鲜明的特色和市场前瞻性，代表着设计时尚；","有极强的艺术性和深厚的文化内涵，能体现品牌的独特美感；","具有空间延展性，能够与展厅内的家具以及饰品构成完整的家居系统。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传展厅图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:37,
        name:"品牌专卖店/品牌运营中心 金奖/优秀奖",
        documentUrl:"2、新锐榜-品牌专卖店、品牌运营中心报名表.docx",
        standards:["专卖店/品牌运营中心定位清晰，在整体布局和设计具有独到之处；","具有鲜明的特色和市场前瞻性，代表着设计时尚；","有极强的艺术性和深厚的文化内涵，能体现品牌的独特美感；","具有空间延展性，能够与店内的家具以及饰品构成完整的家居系统。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传专卖店图 2 张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:38,
        name:"全球化标杆企业",
        documentUrl:"1、新锐榜-全球化标杆企业报名表.docx",
        standards:["企业年销售额超过10亿元，其中海外业务营收占比超过40%；","企业制定有长期的针对全球市场的发展战略和规划；","企业积极践行国际化、全球化，形成“本土+全球”的产业布局；","企业有在全球范围内整合土地、人力、技术、市场等要素资源的能力；","企业品牌的全球化营销情况，在国际市场的认可度，以及国际竞争力和影响力。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"全球化相关证明","placeholder":"上传企业全球化方面的证明文件等，每张大小不超 5M"}),
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传品牌形象图片、企业大楼形象或全球化布局照片 3 张，每张图不超过 5M"}),
        },
    },
    {   
        id:39,
        name:"产业链隐形冠军企业",
        documentUrl:"2、新锐榜-产业链隐形冠军企业报名表.docx",
        standards:["企业长期专注于某一细分行业产品的研发、生产和经营，具有较高的市场份额和市场领袖地位；","企业拥有独特的工艺、技术、产品或提供特色化服务；","细分产品拥有自主知识产权，产品关键性能指标处于国内同类产品的领先水平；","企业年营收在5000万元以上，近三年销售额市场占比位于行业前列，研发投入占年销售收入的3%以上；","具体评分标准见下表；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"隐形冠军证明材料","placeholder":"上传企业实力或获得荣誉等证明文件，每张大小不超 5M"}),
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传品牌形象图片、企业大楼形象或车间装备照片 3 张，每张图不超过 5M"}),
        },
    },
    {   
        id:40,
        name:"金牌供应服务商",
        documentUrl:"3、新锐榜-金牌供应服务商报名表.docx",
        standards:["所提供的产品、技术或服务具有行业竞争力；","所提供的产品、技术或服务对行业发展有推动作用，并得到客户的认可；","信誉度高，业内评价积极正面，销售服务体系完善；","在与客户企业合作的过程中，深受客户的好评，必须有三家（含三家）以上客户并能提供合作证明材料。"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"证明材料","placeholder":"上传不少于3个客户的合作合同盖章页或客户推荐书 ，每张图不超过 5M"}),
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传品牌形象图片、企业大楼形象或车间装备照片 3 张，每张图不超过 5M"}),
        },
    },
    {   
        id:41,
        name:"智能制造先进装备 金奖/优秀奖",
        documentUrl:"4、新锐榜-智能制造先进装备报名表.docx",
        standards:["产品是否在陶瓷机械、装备领域有重大技术突破；","产品是否对陶瓷生产、加工、运输等流程有明显的提升或改善；","产品是否应用了独创的领先技术，是否拥有专利证书；","产品在陶瓷产业领域是否具有较强的引领、示范和带动作用；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"证明文件","placeholder":"上传产品专利证书，产品的应用案例、检测报告，每张图不超过 5M"}),
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，应用图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:42,
        name:"技术工艺领先产品 金奖/优秀奖",
        documentUrl:"5、新锐榜-技术工艺领先产品报名表.docx",
        standards:["近3年该产品获得相关认定或鉴定（如，国际领先、国家发明专利、实用新型专利等）","产品运用了先进技术或工艺创新，与同类产品相比有明显的优势；","产品有望成为行业主流产品，推动产品升级换代；","产品市场增长、销售总量、创造利润等方面表现突出；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"证明文件","placeholder":"上传产品专利证书，产品的应用案例、检测报告，每张图不超过 5M"}),
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，应用图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:43,
        name:"低碳节能先进技术 金奖/优秀奖",
        documentUrl:"6、新锐榜-低碳节能先进技术报名表.docx",
        standards:["产品或技术能否有效降低陶瓷生产、包装、运输、铺贴等环节的碳排放；","产品或技术是否能以更低单位成本的碳排放量创造更大效益；","产品或技术的低碳节能水平在同行业中是否具有先进性；","产品或技术是否自主创新，是否有专利证书；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"证明文件","placeholder":"上传产品专利证书，产品的应用案例、检测报告，每张图不超过 5M"}),
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，应用图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },{   
        id:44,
        name:"绿色环保创新产品 金奖/优秀奖",
        documentUrl:"7、新锐榜-绿色环保创新产品报名表.docx",
        standards:["产品工艺和技术自主创新；","产品有节能或环保技术专利证书；","产品在生产过程中达到国家的能效标准或环境保护标准；","产品在使用过程中能有效节约能源，并对环境保护有推动作用；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"证明文件","placeholder":"上传产品专利证书，产品的应用案例、检测报告，每张图不超过 5M"}),
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图 2 张，应用图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:45,
        name:"新锐榜20年高质量发展企业",
        documentUrl:"1、新锐榜20年-高质量发展企业报名表.docx",
        standards:["要求企业成立时间在2004年3月28日前，企业2023年销售额达到20亿元以上；","近20年来企业规模不断扩大，保持发展增长，取得良好的经济发展效益；","企业注重创新人才的培养和引进，持续投入研发创新，不断推出有竞争力的产品，拥有多项国家发明专利；","产品生产工艺自主创新，品质不断提高，国抽没有重大产品质量问题，拥有完善的质量管理体系和监控机制；","制定绿色制造的中长期规划，不断推进企业绿色低碳发展，有效实现绿色、智能转型；","积极履行企业社会责任，关注环境保护和可持续发展，关注员工权益、不断提升企业的社会影响力，没有出现违法违纪行为和重大的安全生产事故；","具体评分标准见下表；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"相关证明材料","placeholder":"上传相关的技术专利证书，大小不超过 5M"}),
            business_license, //营业执照
            correlations_image,
        },
    },
    {   
        id:46,
        name:"新锐榜20年卓越品牌",
        documentUrl:"2、新锐榜20年-卓越品牌报名表.docx",
        standards:["要求品牌成立时间在2004年3月28日前，2023年品牌销售额达到8亿元以上或2023年企业销售额达到10亿以上；","近20年来品牌在行业和市场终端具有的知名度不断提升，具有广泛的影响力；","持续创新，研发创新成果具有市场竞争优势，对行业具有卓越贡献；","品牌所主导的事件，对推动整个行业的发展、进步具有重要意义；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            trademark_documents, //trademark_documents
            business_license, //营业执照
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传品牌形象图片或企业大楼形象 3 张，每张图不超过 5M"}),
        },
    },
    {   
        id:47,
        name:"新锐榜20年品类发展引领者",
        documentUrl:"3、新锐榜20年-品类发展引领者报名表.docx",
        standards:["在品类发展方面取得了创新成果，其代表产品在市场上表现突出，2023年品牌销售额达到8亿元以上或2023年企业销售额达到10亿以上；","根据市场和消费者的需求，在品类产品研发上持续升级创新，在带动品类发展方面发挥示范作用；","在品类的推广营销方面做出了重要贡献，提升了品类的市场认知度；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图2张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:48,
        name:"新锐榜20年杰出原创IP产品奖",
        documentUrl:"4、新锐榜20年-杰出原创IP产品报名表.docx",
        standards:["1.由企业自主研发，具有品牌独特的原创设计属性，具有较高的品牌辨识度，2023年品牌销售额达到8亿元以上或2023年企业销售额达到10亿以上；","产品已连续推出三代及以上，并会持续迭代升级；","产品在艺术表达、工艺技术等方面具有创新性和引领性，得到市场消费者的广泛关注和认可；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            patent_certificate: Object.assign({},patent_certificate,{"title":"专利或版权证书","placeholder":"上传产品专利证书、版权登记证明、获得的设计奖项等证明文件"}),
            testing_certificate, //质量检测证明
            correlations_image:Object.assign({},correlations_image,{"placeholder":"上传产品图2张，空间效果图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    },
    {   
        id:49,
        name:"新锐榜20年经典产品成就奖",
        documentUrl:"5、新锐榜20年-经典产品成就奖报名表.docx",
        standards:["产品要求是在2013年12月31日之前推出，上市时间10年以上，2023年品牌销售额达到8亿元以上或2023年企业销售额达到10亿以上；","产品在同类别产品中具有代表性和领先性，代表了一定时期的前沿工艺技术；","产品的市场表现突出，广受消费者接受，符合大众的审美标准，至今依然在市场上流行；"],
        obj:"",
        forms: {
            follow_name,
            company,
            basics_file,
            logo_image,
            certificate_image:  Object.assign({},certificate_image,{"title":"证明文件","placeholder":"上传产品专利证书，产品的应用案例、检测报告，每张图不超过 5M"}),
            correlations_image:Object.assign({},correlations_image,{"placeholder":"传产品图 2 张，应用图 2 张，精度要求 300dpi，每张图不超过5M"}),
        },
    }
]

console.log(prizeList)