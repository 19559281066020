
import {prizeList} from '@/api/formdata.js'

export default {
  token:localStorage.token,
  loginShow:false,
  prizes:prizeList,
  prizesIdx:0,
  zoomVisible:false,
  zoomIdx:0,
  zoomList: [],
}