<template>
  <div>
    <div class="nav" ref="nav">
      <router-link to="/m_list">返回</router-link>
    </div>
    <!-- <div class="tips">提示：此页面仅供查看，如需正式报名，请使用电脑登录“陶城网”</div> -->

    <div class="title">{{detail.name}}</div>
    <div class="desp">
      <p>{{detail.desc}}</p>
      <p v-if="detail.obj!==''"><span>评选对象：</span> {{detail.obj}}</p>
      <p><span>评选标准：</span></p>
      <p v-for="(item,index) in detail.standards" :key="index">{{(1+index)+"、"+item}}</p>
    </div>

    <div class="form-desp">
      <div class="t">报名材料要求</div>
      <div class="" v-for="(val,key) in detail.forms" :key="key">
        <div v-if="val.title!='对接人'&&val.title!='类型'">
          <span class="key">{{val.title}}</span>
          <span class="val">（{{val.placeholder}}）</span>
        </div>
      </div>
      <table-desp :id="detail.id"></table-desp>
    </div>

    <div class="tips" style="padding:30px 14px 5px 14px;">提示：此页面仅供查看，如需<span>正式报名，请使用电脑登录“陶城网”</span></div>
    <div class="tips" style="padding:5px 14px 30px 14px;">咨询电话：
      <a href="tel:135 0966 0102">13509660102</a> 李先生；
      <a href="tel:13679710139">136 7971 0139</a> 曾小姐
    </div>
  </div>
</template>

<script>
import tableDesp from '../apply/components/table/table-desp.vue';


export default {
  name: '',
  components: {tableDesp},
  data(){
    return {

    }
  },
  mounted(){
    window.addEventListener("scroll",this.handleScroll2); 
  },
  destroyed() {
    // console.log("detail destroyed");
    document.removeEventListener('scroll', this.handleScroll2)
  },
  computed:{
    detail(){
      let detail = this.$store.getters.formDetail;
      if(detail===undefined){
        detail=JSON.parse(localStorage.detail);
      }else{
        localStorage.detail = JSON.stringify(detail);
      }
      return detail;
    },
  },
  methods:{
    createFormData(detail){
      let form = {};
      for(let key in detail.forms){
        form[detail.forms[key].name]='';
        if(!!detail.forms[key].files&&detail.forms[key].type==="textarea"){
          form[detail.forms[key].files.name]='';
        }
      }
      this.form = form;
    },
    handleScroll2(){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(scrollTop);
    
      if(scrollTop>168){
        this.$refs.nav&&(this.$refs.nav.style.position="fixed");
      }else{
        this.$refs.nav&&(this.$refs.nav.style.position="absolute");
      }
    }
  }
}
</script>

<style scoped>
/*.nav{height:38px; line-height:38px; text-align:center; background: #b7002e; color:#fff; font-size:16px;}
.nav>a{position: absolute; height:38px; line-height:38px; display:block; width:60px; text-align:center; color:#fff;}*/
.nav{height:38px; line-height:38px; text-align:center; background: #b50333; color:#fff; font-size:16px; position: absolute; left:0;right:0; top:0;}
.nav>a{position: absolute; height:38px; line-height:38px; display:block; width:60px; text-align:center; color:#fff;}
.tips{font-size:13px; color:#e7be86; padding:14px 14px;}
.tips>a{color:#e7be86;}
.tips>span{color:#fff;}

.title{padding:0 14px; font-size: 25px; color:#e7be86; margin:0px 0 15px 0; padding-top:55px; font-weight: bold;}
.desp{padding:0 14px; line-height:24px; font-size:14px; color:#8793e5;}
.desp span{color:#e7be86;}

.form-desp{padding:0 14px; line-height:24px; font-size:14px;}
.form-desp .t{color:#e7be86; margin-top:33px;}
.form-desp .key{color:#e7be86}
.form-desp .val{color:#8793e5;}
</style>