import {createRouter, createWebHistory} from 'vue-router'
const routerHistory = createWebHistory()

const routes = [
  {
    path: '/',
    redirect: '/index'
  }
  // ,{
  //   path:'/index',
  //   component:() => import('@/pages/apply/index')
  //   // component:() => import('@/pages/index/index')
  // }
  ,{
    path:'/index',
    component:() => import('@/pages/apply/index')
  }
  ,{
    path:'/form',
    component:() => import('@/pages/apply/form')
  }
  ,{
    path:'/edit',
    component:() => import('@/pages/apply/edit')
  }
  ,{
    path:'/record',
    component:() => import('@/pages/record/index')
  }
  ,{
    path:'/m_index',
    component:() => import('@/pages/mobile/list')
    // component:() => import('@/pages/mobile/index')
  }
  ,{
    path:'/m_list',
    component:() => import('@/pages/mobile/list')
  }
  ,{
    path:'/m_detail',
    component:() => import('@/pages/mobile/detail')
  }
]

const router = createRouter({
	history: routerHistory,
	scrollBehavior: () => ({ y: 0 }),
	routes
})


export default router