<template>
	<div>
	<div class="table-title">数智化标杆企业资料填报表<span v-if="!loading" class="del-storage" @click="clearStorage">清空缓存</span></div>
  	<table border="1" class="table-order" ref="table">
      	<!-- <thead>
	        <tr>
	          <th colspan="6">低碳发展先进企业（工厂）资料填报表</th>
	        </tr>
      	</thead> -->
      	<tbody>
	      	<tr>
	      		<td>企业全称</td>
	      		<td colspan="2"><input @blur="blur" type="" v-model="formdata.v0" /></td>
	      		<td>品牌名称</td>
	      		<td colspan="2"><input @blur="blur" type="" v-model="formdata.v1" /></td>
	      	</tr>
	      	<tr class="hasTextarea">
	      		<td>企业简介<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="blur($event,'v2')" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v2"></div>
	      			<!-- <textarea @blur="blur" v-model="formdata.v" class="textarea" resize="none"></textarea> -->
	      		</td>
	      	</tr>
	      	<!-- <tr class="hasTextarea">
	      		<td>拳头产品<br/>产销比例<br/>(500字以内）</td>
	      		<td colspan="5" style="position: relative;">
	      			<div style="position: relative;z-index:9;" @blur="blur($event,'v5')" name="v5" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v5" 
					  placeholder="请描述该类产品生产情况、销售面积、市场份额、主要客户等，在本公司所有产品中的占比情况等"></div>
	      		</td>
	      	</tr> -->

	      	<tr>
	      		<td class="td1">数智化资金投入<br/>2022年</td>
	      		<td class="td2"><input @blur="blur" type="" v-model="formdata.v6" /></td>
	      		<td class="td3">数智化相关人才数量<br/>2022年</td>
	      		<td class="td4"><input @blur="blur" type="" v-model="formdata.v7" /></td>
	      		<td class="td5">数智化专利数量<br/>2020-2022年</td>
	      		<td class="td6"><input @blur="blur" type="" v-model="formdata.v8" /></td>
	      	</tr>

	      	<tr class="hasTextarea">
	      		<td>数智化建设历程<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="blur($event,'v9')" name="v9" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v9"></div>
	      		</td>
	      	</tr>

	      	<tr class="hasTextarea">
	      		<td>数智化成果介绍<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="blur($event,'v10')" name="v10" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v10"></div>
	      		</td>
	      	</tr>
			
	      	<tr class="hasTextarea">
	      		<td>其它说明<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="blur($event,'v12')" name="v12" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v12"></div>
	      		</td>
	      	</tr>

    	</tbody>
    </table>
	</div>
</template>

<script>
import { ref, reactive,onMounted,onUpdated, computed,toRaw} from 'vue'
import { ElMessageBox} from 'element-plus'


  export default{
    name: 'table3',
	props:{loading:{type:Boolean,default:false}},
    setup(props,{emit}){
    	let ctable2 = {};
    	if(!!localStorage.getItem('ctable2')){
    		ctable2 = JSON.parse(localStorage.getItem('ctable2'))
    	}else{
    		for(let i=0;i<35; i++){
    			ctable2['v'+i] = ''
    		}
    		console.log(ctable2);
    	}
    	const formdata = reactive(ctable2);


    	const table = ref();
    	const blur = (e,name)=>{
    		console.log(e,name);

			let nodeList = table.value.querySelectorAll('tr');
			let sumHeight = 0;
			for(let i=0; i<nodeList.length; i++){
				let trHeight = window.getComputedStyle(nodeList[i]).height.replace("px","")*1;
				sumHeight+=trHeight;
				//textarea自适应高度
				// if(nodeList[i].className==='hasTextarea'){
				// 	let textarea = nodeList[i].querySelectorAll('.textarea')[0];
				// 	textarea.style.height = "inherit";
				// 	textarea.style.height = textarea.scrollHeight+'px';
				// }
				console.log(sumHeight)
			}

			if(!!name){
				let targetElem = e.target;
				//删掉多余空行
				targetElem.innerHTML = targetElem.innerHTML.replace(/<div><br><\/div>/g, '');
				formdata[name] = targetElem.innerHTML;
				console.log(targetElem.innerHTML)
			}
			localStorage.setItem("ctable2", JSON.stringify(toRaw(formdata)));
			// emit("dinput",toRaw(formdata));
    	}
    	const paste = (e)=>{
    		e.preventDefault();
	        var text = null;
	    
	        if(window.clipboardData && clipboardData.setData) {
	            // IE
	            text = window.clipboardData.getData('text');
	        } else {
	            text = (e.originalEvent || e).clipboardData.getData('text/plain') || prompt('在这里输入文本');
	        }
	        if (document.body.createTextRange) {    
	            if (document.selection) {
	                textRange = document.selection.createRange();
	            } else if (window.getSelection) {
	                sel = window.getSelection();
	                var range = sel.getRangeAt(0);
	                
	                // 创建临时元素，使得TextRange可以移动到正确的位置
	                var tempEl = document.createElement("span");
	                tempEl.innerHTML = "&#FEFF;";
	                range.deleteContents();
	                range.insertNode(tempEl);
	                textRange = document.body.createTextRange();
	                textRange.moveToElementText(tempEl);
	                tempEl.parentNode.removeChild(tempEl);
	            }
	            textRange.text = text;
	            textRange.collapse(false);
	            textRange.select();
	        } else {
	            // Chrome之类浏览器
	            document.execCommand("insertText", false, text);
	        }

    	}
		const clearStorage = ()=>{
			ElMessageBox.alert('是否清空表单缓存？', '清空缓存', {
				confirmButtonText: '确定',
				callback: (action) => {
					console.log(action);
					if(action==='confirm'){
						localStorage.removeItem("ctable2");
						Object.keys(formdata).forEach(function(key){
							formdata[key] = '';
						});
					}
				},
			})
		}
    	return {
    		blur,
    		table,
    		paste,
    		formdata,
			clearStorage
    	}
    }
  }
</script>

<style scoped>
.table-title{font-weight: bold;}
table{width: 100%; margin-top:5px; font-size:14px;}
table thead{background:#f6f6f6;}
table thead th{padding: 5px 5px;}
table .tr-title{font-weight: bold;}

table td{padding: 6px 8px; line-height:14px; position: relative;}
table td:nth-of-type(1){width:90px; line-height: 18px;}
/*table td:nth-of-type(3){width:120px; line-height: 18px;}*/

table td:nth-of-type(2n+1){text-align: center;}
table td input{width:100%; border:1px solid #ddd; padding:5px 5px; box-sizing: border-box;}
table td input:focus {outline:none; border: 1px solid #b70038;}
table td textarea{width:100%; border:1px solid #ddd; padding:5px 5px; box-sizing: border-box;}
table td textarea:focus {outline:none; border: 1px solid #b70038;}
table td.td1{width:120px;}
.textarea{width: 100%; min-height:100px; margin-left: auto;margin-right: auto;outline: 0;border: 1px solid #ddd;line-height: 22px;padding:5px 5px;word-wrap: break-word;overflow-x: hidden;overflow-y: auto;box-sizing: border-box; resize:none; overflow: hidden; transition: all 0.2s linear;}
.textarea:focus{outline:none; border: 1px solid #b70038;}


.inherit-input{text-align: center;}
.inherit-input input{width:60px; border:none; border-bottom:1px solid #666; text-align: center; margin:0 4px;}
.inherit-input input:focus{outline:none; border:none; border-bottom: 1px solid #b70038;}

/* .placeholder{position:absolute; left:0; right:0; top:50%; margin-top:-9px; text-align: center; z-index: 8; color:#999;} */
span.del-storage{color:#b70038; text-decoration: underline; cursor: pointer; margin-left:10px;}

.textarea:empty:before{
	content:attr(placeholder);
    font-size: 13px;
    color: #999;
	display: block; text-align:center; position:absolute; left:0; right:0; top:50%; margin-top:-9px;
}
.textarea:not(:empty):focus:before{content:none;}
</style>