<template>
  <div class="btn">
    {{title}}
  </div>
</template>

<script>
  export default{
    name: 'btn',
    props: {
      title:String
    }
  }
</script>

<style scoped>
  .btn{width:153px; height:44px; line-height:44px; border:1px solid #6a1329; color:#ffdebb; font-size:18px; text-align:center; cursor:pointer; transition-duration: 300ms; border-radius:5px; user-select: none; background: #b70038; box-shadow: 1px 1px 8px #000;}
  .btn:hover{background:#ffdebb; border:1px solid #ffdebb; color:#b70038; box-shadow: 1px 2px 3px #000;}
</style>