<template>
	<div>
	<div class="table-title">低碳发展先进企业资料填报表<span v-if="!loading" class="del-storage" @click="clearStorage">清空缓存</span></div>
  	<table border="1" class="table-order" ref="table">
      	<!-- <thead>
	        <tr>
	          <th colspan="6">低碳发展先进企业（工厂）资料填报表</th>
	        </tr>
      	</thead> -->
      	<tbody>
	      	<tr>
	      		<td>企业全称</td>
	      		<td colspan="2"><input @blur="input" type="" v-model="formdata.v0" /></td>
	      		<td>企业简称</td>
	      		<td colspan="2"><input @blur="input" type="" v-model="formdata.v1" /></td>
	      	</tr>
	      	<tr>
	      		<td>申报工厂名称</td>
	      		<td colspan="2"><input @blur="input" type="" v-model="formdata.v2" /></td>
	      		<td>工厂地址</td>
	      		<td colspan="2"><input @blur="input" type="" v-model="formdata.v3" /></td>
	      	</tr>
	      	<tr>
	      		<td class="td1">生产线数量</td>
	      		<td class="td2"><input @blur="input" type="" v-model="formdata.v4" /></td>
	      		<td class="td3">年产能</td>
	      		<td class="td4"><input @blur="input" type="" v-model="formdata.v5" /></td>
	      		<td class="td5">工厂员工数量</td>
	      		<td class="td6"><input @blur="input" type="" v-model="formdata.v6" /></td>
	      	</tr>
	      	<tr class="hasTextarea">
	      		<td>企业/工厂有关介绍<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="input($event,'v7')" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v7"></div>
	      			<!-- <textarea @blur="input" v-model="formdata.v" class="textarea" resize="none"></textarea> -->
	      		</td>
	      	</tr>
	      	<tr>
	      		<td>产品生产范围</td>
	      		<td colspan="5"><input @blur="input" type="" v-model="formdata.v8" /></td>
	      	</tr>

	      	<tr class="subtitle">
	      		<td colspan="6">碳排放总额（吨）</td>
	      	</tr>
	      	<tr>
	      		<td class="td1">2022年</td>
	      		<td class="td2"><input @blur="input" type="" v-model="formdata.v9" /></td>
	      		<td class="td3">2021年</td>
	      		<td class="td4"><input @blur="input" type="" v-model="formdata.v10" /></td>
	      		<td class="td5">2020年</td>
	      		<td class="td6"><input @blur="input" type="" v-model="formdata.v11" /></td>
	      	</tr>
	      	<tr>
	      		<td class="td1">同比下降数值</td>
	      		<td class="td2"><input @blur="input" type="" v-model="formdata.v12" /></td>
	      		<td class="td3">同比下降数值</td>
	      		<td class="td4"><input @blur="input" type="" v-model="formdata.v13" /></td>
	      		<td class="td5">同比下降数值</td>
	      		<td class="td6"><input @blur="input" type="" v-model="formdata.v14" /></td>
	      	</tr>

	      	<tr class="subtitle">
	      		<td colspan="6">年生产总能耗（万吨标煤）</td>
	      	</tr>
	      	<tr>
	      		<td class="td1">2022年</td>
	      		<td class="td2"><input @blur="input" type="" v-model="formdata.v15" /></td>
	      		<td class="td3">2021年</td>
	      		<td class="td4"><input @blur="input" type="" v-model="formdata.v16" /></td>
	      		<td class="td5">2020年</td>
	      		<td class="td6"><input @blur="input" type="" v-model="formdata.v17" /></td>
	      	</tr>
	      	<tr>
	      		<td class="td1">同比下降数值</td>
	      		<td class="td2"><input @blur="input" type="" v-model="formdata.v18" /></td>
	      		<td class="td3">同比下降数值</td>
	      		<td class="td4"><input @blur="input" type="" v-model="formdata.v19" /></td>
	      		<td class="td5">同比下降数值</td>
	      		<td class="td6"><input @blur="input" type="" v-model="formdata.v20" /></td>
	      	</tr>

	      	<tr class="subtitle">
	      		<td colspan="6">总电耗（万千瓦时）</td>
	      	</tr>
	      	<tr>
	      		<td class="td1">2022年</td>
	      		<td class="td2"><input @blur="input" type="" v-model="formdata.v21" /></td>
	      		<td class="td3">2021年</td>
	      		<td class="td4"><input @blur="input" type="" v-model="formdata.v22" /></td>
	      		<td class="td5">2020年</td>
	      		<td class="td6"><input @blur="input" type="" v-model="formdata.v23" /></td>
	      	</tr>
	      	<tr>
	      		<td class="td1">同比下降数值</td>
	      		<td class="td2"><input @blur="input" type="" v-model="formdata.v24" /></td>
	      		<td class="td3">同比下降数值</td>
	      		<td class="td4"><input @blur="input" type="" v-model="formdata.v25" /></td>
	      		<td class="td5">同比下降数值</td>
	      		<td class="td6"><input @blur="input" type="" v-model="formdata.v26" /></td>
	      	</tr>

	      	<tr class="subtitle">
	      		<td colspan="6">单位面积产品能耗（公斤标煤）</td>
	      	</tr>
	      	<tr>
	      		<td class="td1">2022年</td>
	      		<td class="td2"><input @blur="input" type="" v-model="formdata.v27" /></td>
	      		<td class="td3">2021年</td>
	      		<td class="td4"><input @blur="input" type="" v-model="formdata.v28" /></td>
	      		<td class="td5">2020年</td>
	      		<td class="td6"><input @blur="input" type="" v-model="formdata.v29" /></td>
	      	</tr>
	      	<tr>
	      		<td class="td1">同比下降数值</td>
	      		<td class="td2"><input @blur="input" type="" v-model="formdata.v30" /></td>
	      		<td class="td3">同比下降数值</td>
	      		<td class="td4"><input @blur="input" type="" v-model="formdata.v31" /></td>
	      		<td class="td5">同比下降数值</td>
	      		<td class="td6"><input @blur="input" type="" v-model="formdata.v32" /></td>
	      	</tr>

	      	<tr class="hasTextarea">
	      		<td>减碳措施<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="input($event,'v33')" @paste="paste" class="textarea" contenteditable="true"  v-html="formdata.v33"></div>
	      			<!-- <textarea @blur="input" v-model="formdata.v" class="textarea" resize="none"></textarea> -->
	      		</td>
	      	</tr>

	      	<tr class="hasTextarea">
	      		<td>示范作用<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="input($event,'v34')" name="v34" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v34"></div>
	      			<!-- <textarea @blur="input" v-model="formdata.v" class="textarea" resize="none"></textarea> -->
	      		</td>
	      	</tr>

    	</tbody>
    </table>
	</div>
</template>

<script>
import { ref, reactive,onMounted,onUpdated, computed,toRaw} from 'vue'
import { ElMessageBox} from 'element-plus'

  export default{
    name: 'table2',
	props:{loading:{type:Boolean,default:false}},
    setup(props,{emit}){
    	let ctable = {};
    	if(!!localStorage.getItem('ctable')){
    		ctable = JSON.parse(localStorage.getItem('ctable'))
    	}else{
    		for(let i=0;i<35; i++){
    			ctable['v'+i] = ''
    		}
    		console.log(ctable);
    	}
    	const formdata = reactive(ctable);


    	const table = ref();
    	const input = (e,name)=>{
    		console.log(e,name);

			let nodeList = table.value.querySelectorAll('tr');
			let sumHeight = 0;
			for(let i=0; i<nodeList.length; i++){
				let trHeight = window.getComputedStyle(nodeList[i]).height.replace("px","")*1;
				sumHeight+=trHeight;
				//textarea自适应高度
				// if(nodeList[i].className==='hasTextarea'){
				// 	let textarea = nodeList[i].querySelectorAll('.textarea')[0];
				// 	textarea.style.height = "inherit";
				// 	textarea.style.height = textarea.scrollHeight+'px';
				// }
				console.log(sumHeight)
			}

			if(!!name){
				let targetElem = e.target;
				//删掉多余空行
				targetElem.innerHTML = targetElem.innerHTML.replace(/<div><br><\/div>/g, '');
				formdata[name] = targetElem.innerHTML;
				console.log(targetElem.innerHTML)
			}
			localStorage.setItem("ctable", JSON.stringify(toRaw(formdata)));
			// emit("dinput",toRaw(formdata));
    	}
    	const paste = (e)=>{
    		e.preventDefault();
	        var text = null;
	    
	        if(window.clipboardData && clipboardData.setData) {
	            // IE
	            text = window.clipboardData.getData('text');
	        } else {
	            text = (e.originalEvent || e).clipboardData.getData('text/plain') || prompt('在这里输入文本');
	        }
	        if (document.body.createTextRange) {    
	            if (document.selection) {
	                textRange = document.selection.createRange();
	            } else if (window.getSelection) {
	                sel = window.getSelection();
	                var range = sel.getRangeAt(0);
	                
	                // 创建临时元素，使得TextRange可以移动到正确的位置
	                var tempEl = document.createElement("span");
	                tempEl.innerHTML = "&#FEFF;";
	                range.deleteContents();
	                range.insertNode(tempEl);
	                textRange = document.body.createTextRange();
	                textRange.moveToElementText(tempEl);
	                tempEl.parentNode.removeChild(tempEl);
	            }
	            textRange.text = text;
	            textRange.collapse(false);
	            textRange.select();
	        } else {
	            // Chrome之类浏览器
	            document.execCommand("insertText", false, text);
	        }

    	}
		const clearStorage = ()=>{
			ElMessageBox.alert('是否清空表单缓存？', '清空缓存', {
				confirmButtonText: '确定',
				callback: (action) => {
					console.log(action);
					if(action==='confirm'){
						localStorage.removeItem("ctable");
						Object.keys(formdata).forEach(function(key){
							formdata[key] = '';
						});
					}
				},
			})
		}
    	return {
    		input,
    		table,
    		paste,
    		formdata,
			clearStorage
    	}
    }
  }
</script>

<style scoped>
.table-title{font-weight: bold;}
table{width: 100%; margin-top:5px; font-size:14px;}
table thead{background:#f6f6f6;}
table thead th{padding: 5px 5px;}
table .tr-title{font-weight: bold;}

table td{padding: 6px 8px; line-height:14px;}
table td:nth-of-type(1){width:100px; line-height: 18px;}
table td:nth-of-type(3){width:190px; line-height: 18px;}
table td:nth-of-type(5){width:190px; line-height: 18px;}

table td:nth-of-type(2n+1){text-align: center;}
table td input{width:100%; border:1px solid #ddd; padding:5px 5px; box-sizing: border-box;}
table td input:focus {outline:none; border: 1px solid #b70038;}
table td textarea{width:100%; border:1px solid #ddd; padding:5px 5px; box-sizing: border-box;}
table td textarea:focus {outline:none; border: 1px solid #b70038;}
table td.td1{width:120px;}
.textarea{width: 100%; min-height:100px; margin-left: auto;margin-right: auto;outline: 0;border: 1px solid #ddd;line-height: 22px;padding:5px 5px;word-wrap: break-word;overflow-x: hidden;overflow-y: auto;box-sizing: border-box; resize:none; overflow: hidden; transition: all 0.2s linear;}
.textarea:focus{outline:none; border: 1px solid #b70038;}

.subtitle{font-weight: bold; color:#111; background:#f1f1f1;}
span.del-storage{color:#b70038; text-decoration: underline; cursor: pointer; margin-left:10px;}
</style>