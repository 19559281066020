<template>
  <div class="zoombox" :class="{show:visible}">
    <div class="close" @click="close"><el-icon><close-bold /></el-icon></div>
    <div class="swiper-container swiper-banner" id="swiperBanner">
        <div class="swiper-wrapper">
          <div v-for='(i,index) in list' :key='index'  class="swiper-slide"  @click="close">
              <img class="img" :src="i"  @click.stop="">
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
     </div>
   </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css';
import { CloseBold } from '@element-plus/icons-vue'


export default {
  props:{
    visible:{type:Boolean, default:false}
  },
  components:{CloseBold},
  data () {
     return {
        swiper:null,
     }
  },
  computed:{
    initIdx(){
      return this.$store.state.zoomIdx
    },
    list(){
      return this.$store.state.zoomList
    }
  },
  methods:{
    close(){
      this.$store.commit("setZoomVisible",false);
    }
  },
  mounted:function(){
    this.$nextTick( () => {
      this.swiper = new Swiper('#swiperBanner', {
          autoplay: false,
          observer:true,
          observeParents:true,
          pagination: {
            el: '.swiper-pagination',
          },
          initialSlide:this.initIdx,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        })
      })
  },
}
</script>

<style>
.zoombox{visibility: hidden; position: fixed; z-index:9999; top:0; bottom:0; left:0; right:0; background:rgba(0,0,0,0); transition-duration: 300ms;}
.zoombox.show{visibility: visible; background:rgba(0,0,0,.8);}
.zoombox .close{position: absolute; right: 1.5vw; top: 1.2vw;font-size: 2vw;color: #fff; cursor: pointer;-webkit-transition: all .5s;transition: all .5s; z-index: 10;}
.zoombox .close:hover{transform: rotate(90deg);}

.swiper-banner{width:100%; height:100vh;}
.swiper-banner .swiper-slide{height:100vh; display:flex; opacity:0; transform: scale(0.7); transition-duration: 300ms;}
.zoombox.show .swiper-banner .swiper-slide{opacity: 1; transform: scale(1);}
.swiper-banner .swiper-slide img{margin:auto; max-width:100%; max-height: 100vh;}
.swiper-pagination-bullet-active{background: #d70e19!important;}
.swiper-button-next,.swiper-button-prev{color:#d70e19;}
@media (max-width: 991px){
.zoombox .close{right: 1vw; top: 1.2vw; font-size:8vw;}
}
</style>
