<template>
  <header>
    <div class="login-state">
<!--       <router-link class="btn" to="/index">首页</router-link>
      <router-link class="btn" to="/record?tab=0" v-if="token!==undefined">已报名奖项</router-link>
      <router-link class="btn" to="/record?tab=1" v-if="token!==undefined">草稿箱</router-link> -->
      <span class="btn login" @click="login" v-if="token===undefined">登录</span>
      <span class="btn exit" @click="exit" v-if="token!==undefined">退出登录</span>
    </div>
    <div class="nav">
      <div class="wrap">
        <router-link class="menu" to="/index">首页</router-link>
        <!-- <router-link class="menu" to="/apply">我要报名</router-link> -->
        <router-link class="menu" to="/record" v-if="!!token">已报名</router-link>
        <a href="https://xinruihero.fstcb.com/" class="menu" target="_blank">历届英雄榜</a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'headerbar',
  props: {
    msg: String
  },
  computed:{
    token(){return this.$store.state.token},
  },
  methods:{
    login(){
      if(this.token===undefined){
        this.$store.commit('setLoginShow',true);
      }
    },
    exit(){
      localStorage.removeItem("token");
      this.$store.commit('setToken',undefined);
      this.$router.replace('/index');
    }
  }
}
</script>

<style scoped>
header{height:550px; background:#b3162b url(../assets/header-bg2.jpg) top center no-repeat; position: relative;}
header .nav{position: absolute; bottom:0; right:0; left:0; height:76px; background: url(../assets/nav-bg.jpg) top center no-repeat;}
header .nav .wrap{width:865px; margin:16px auto 0 auto; font-size:0; text-align: center;}
header .nav .wrap .menu{display:inline-block; height:43px; line-height: 43px; color:#111; width:162px; text-align:center; font-size: 17px; text-decoration: none; transition-duration: 300ms; border:1px solid #d1ac75; background:#c4a473; margin:0 25px; box-shadow: -1px 5px 4px #22211f;}


header .nav .wrap .menu.router-link-exact-active.router-link-active, 
header .nav .wrap .menu:hover
{background:#f6d6a7; color:#111; border:1px solid #a72d22;}

.login-state{width:865px; margin:0 auto 0 auto; padding-top:20px; color:#fff; text-align:right;}
.login-state .btn{cursor:pointer; margin-left:10px; color:#fff;}
.login-state .btn:hover{text-decoration: underline;}
</style>
