<template>
  <div class="title-box">
    <span>{{title}}</span>
    <span>{{eng}}</span>
  </div>
</template>

<script>
  export default{
    name: 't',
    props: {
      title:String,
      eng:String
    }
  }
</script>

<style scoped>
  .title-box{display:inline-block; height:28px; color:#eea95e; font-size:0;}
  .title-box>span:nth-of-type(1){line-height:28px;font-size: 28px; padding-right:10px; margin-right:10px; border-right:1px solid #eea95e;vertical-align: middle; }
  .title-box>span:nth-of-type(2){line-height:16px; font-size:16px; vertical-align: middle;}
</style>