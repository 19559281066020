<template>
  <div class="upload-box">

    <ul class="img-box" v-if="fs.length>0&&type==='image'">
      <li v-for="(item,index) in fs_path" :key="index">
        <img :src="item" alt="" @click="zoom(index)">
        <div class="del" @click="del(index)" v-if="!isPreview"><el-icon><close-bold /></el-icon></div>
      </li>
    </ul>

    <div class="file-box" v-if="fs.length>0&&type==='file'">
        <a class="name" :href="fs_path[0]" :download="fs[0].split('/')[fs[0].split('/').length-1]"><el-icon><Download /></el-icon>{{fs[0].split('/')[fs[0].split('/').length-1]}}</a>
        <div class="del" @click="del(0)" v-if="!isPreview"><el-icon><close-bold /></el-icon></div>
    </div>
    
    <div class="btn-box" v-if="fs.length<nums&&!isPreview">
      <div class="btn-input">
        <div class="progress" :style="{width:progress}"></div>
        <input type="file" @change="changeImg" required="required" :accept="accept">
        {{progress==='0%'?title:progress}}
      </div>
      <div class="text" v-html="placeholder"></div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { CloseBold,Download } from '@element-plus/icons-vue'



export default {
  name: 'cupload',
  components:{CloseBold,Download},
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    type:{
      type:String,default:'image'
    },
    files:{
      type:Array,default:()=>{return []}
    },
    files_path:{
      type:Array,default:()=>{return []}
    },
    isPreview:{type:Boolean,default:false},
    name:String, //input name值
    title: String,
    placeholder:String,
    value:Array,
    size:Number,
    nums:Number
  },
  data(){
    console.log(this.files);
    return {
      fs:this.files,
      fs_path:this.files_path,
      progress:'0%'
    }
  },
  computed:{
    token(){return this.$store.state.token;},
    accept(){
      if(this.type==='image'){
        return 'image/*';
      }else{
        return '.docx';
      }
      
    }
  },
  methods:{
    zoom(index){
      this.$store.commit("setZoomIdx",index);
      this.$store.commit("setZoomList",this.fs);
      this.$store.commit("setZoomVisible",true);
    },
    changeImg(e){
      e.preventDefault();
      let _this = this;
      let fs = e.currentTarget.files;
      console.log(e.currentTarget.files);

      //文件上传
      if(this.type==='file'){
        if(fs[0].size>400*1024*1024){
          this.$message.error('文件最大不超过400M！');
        }else{
          this.uploadFile(fs[0]);
        }
        return false;
      }

      //检测图片格式
      let suffix=(/\.[a-z0-9]+$/gi.exec(fs[0].name)) && (/\.[a-z0-9]+$/gi.exec(fs[0].name))[0].replace('.','').toLowerCase();//文件后缀
      console.log(suffix);
      let imgType = ['jpg','png','gif','jpeg'];
      if((!suffix) || imgType.indexOf(suffix) === -1){
        this.$message.error("图片格式错误，只支持"+imgType.join('、')+'类型图片')
        return false;
      }
      //检测图片容量size
      if(fs[0].size>10*1024*1024){
        this.$message.error('图片最大不超过10M！');
        return false;
      }


      //检测图片分辨率
      let reader = new FileReader();
      reader.readAsDataURL( fs[0] );
      reader.onload = function ( ev ) {
        let imgurl=ev.target.result;
        let newImg= new Image();
        newImg.src=imgurl;
        newImg.crossOrigin = '';
        newImg.onload=function(){
          let iw=newImg.width;
          let ih=newImg.height;
          if(iw>7000||ih>7000){
            _this.$message.error("图片分辨率最大不能超过7000*7000！");
          }else{
            _this.uploadFile(fs[0]);
          }
        }
      }
    },
    uploadFile(file){
      let formData = new FormData();
      formData.append('token',this.token);
      // formData.append('filesNum',1);
      // formData.append('fileName','bigThingsFiles');
      // formData.append('img_file','bigThingsFiles');
      formData.append('img_file',file);
      let config = {
        headers:{'Content-Type':'multipart/form-data'},
        onUploadProgress: progressEvent => {
            var complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
            console.log(complete)
            if(complete==="100%"){
              complete = '处理中...';
            }
            this.progress = complete;
        }
      };
      axios.post('https://20thxrbadmin.fstcb.com/api/v1/update/file',formData, config).then(res=>{
        console.log(res);
        setTimeout(()=>{
          this.progress="0%";
        },500);
        //errcode: 1  errmsg: "验证信息有误"
        if(res.data.errcode===200){
          this.fs.push(res.data.data.file_name);
          this.fs_path.push(res.data.data.file_path);
          
          this.$emit('upload',this.fs);
        }else{
          this.$message.error(res.data.errmsg);
          if(res.data.errmsg==="验证信息有误"){
            this.$store.commit('setLoginShow',true);
          }
        }
      })    
    },
    del(index){
      this.fs.splice(index, 1);
      this.fs_path.splice(index, 1);
      this.$emit('upload',this.fs);
    }
  }
}
</script>

<style scoped>
.upload-box ul{display:flex; flex-wrap: wrap; margin:10px 15px;}
.upload-box ul li{display:flex; width:100px; height:100px; margin:0 5px 5px 0; position: relative; background:#f1f1f1;}
.upload-box ul li img{max-width:100px; max-height:100px; margin:auto; cursor:pointer;}
.upload-box ul li .del{position: absolute; top:0; right:0; width:20px; height:20px; line-height:20px; text-align:center; background:#eee; cursor:pointer;}
.upload-box ul li .del:hover{background:#ccc;}

.file-box{display: flex; margin:0 15px 15px 15px;}
.file-box .name{color:#333; text-decoration: underline;}
.file-box .name .el-icon{font-size:14px; margin-top: -2px; vertical-align: middle;margin-right: 2px;}
.file-box .del{margin-left:10px; color:#c80003; cursor:pointer; margin-top:2px; font-size:14px;}

.btn-box{margin:0px 15px 15px 15px; display: flex; position: relative; align-items: center; }
.btn-box .progress{position: absolute; top:0; bottom:0; left:0; width:50%; background:#cc0526; opacity:.12;}
.btn-box .btn-input{flex:0 0 auto; position: relative; display: inline-block; padding:0 15px; min-width:58px; height:36px; line-height:36px; border:1px solid #b70038; font-size:14px; text-align:center; overflow:hidden; border-radius:5px; color:#fff; background: #b70038; transition-duration: 300ms;}
.btn-box .btn-input:hover{color:#fff; background:#cc0526;}
.btn-box .btn-input input[type="file"]{position: absolute; top:0; bottom:0; left:0; right:0; opacity:0;}
.btn-box .text{flex:1 1 auto; margin-left:10px; color:#9b9b9b; line-height: 18px;}
</style>
