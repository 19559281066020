<template>
  <div>
    <div class="title">{{detail.name}}</div>
    <div class="desp">
      <p>{{detail.desc}}</p>
      <p style="font-weight:blod;" v-if="detail.obj!==''"><span>评选对象：</span> {{detail.obj}}</p>
      <p style="font-weight:blod;"><span>评选标准：</span></p>
      <p v-for="(item,index) in detail.standards" :key="index">{{(1+index)+"、"+item}}</p>
      <table-desp :id="detail.id"></table-desp>

    </div>
    <div class="form-box">
        <el-form action="" :model="form" :rules="rules" ref="form">
            <h2 class="t">2024新锐榜-{{detail.name}}</h2>

            <div v-for="(val,key) in detail.forms" :key="key">
              <c-input v-if="val.type==='text'" 
                :title="val.title" 
                :placeholder="val.placeholder"
                :name="val.name"
                 v-model="form[val.name]">
              </c-input>
              
              <c-select v-if="val.type==='radio'" 
                :title="val.title" 
                :placeholder="val.placeholder" 
                :options="val.value" 
                v-model="form[val.name]">
              </c-select>

              <c-base
                :formid="detail.id"
                v-if="baseIsShow&&val.name=='basicInformation'" 
                v-on:upload="uploadEnd($event,'basicInformation')">
              </c-base>

              <div class="big-box" v-if="val.type==='textarea'">
                <label class="label" for="name">{{val.title}}</label>
                <c-textarea :placeholder="val.placeholder" :name="val.name" v-model="form[val.name]"></c-textarea>
                <c-upload v-if="!!val.files" 
                  v-on:upload="uploadEnd($event,val.files.name)"
                  :title="val.files.title" 
                  :size="500"
                  :nums="2"
                  :placeholder="val.files.placeholder">
                </c-upload>
              </div>

              <div class="big-box" v-if="val.type==='file'&&val.accept==='image/*'&&val.name!='basicInformation'">
                <label class="label" for="name">
                  {{val.title}}
                  <!-- <a href="https://17thxrb.fstcb.com/files/%E5%8A%9F%E8%83%BD%E6%80%A7%E7%93%B7%E7%A0%96%E8%A1%A5%E5%85%85%E8%B5%84%E6%96%99.docx" target="_bland" class="download-a" v-if="detail.id===23&&val.name==='testReport'">点击下载“功能性说明表格”</a> -->
                </label>
                <c-upload 
                  :title="!!val.buttonTitle?val.buttonTitle:'上传图片'" 
                  v-on:upload="uploadEnd($event,val.name)"
                  :size="2048"
                  :nums="val['data-pics']*1"
                  :placeholder="val.placeholder">
                </c-upload>
              </div>

              <div class="big-box" v-if="val.type==='file'&&val.accept!=='image/*'">
                <label class="label label-flex" for="name">
                  <div>{{val.title}}</div>
                  <div class="downloadbox"><a :href="'https://20thxrb.fstcb.com/document/'+detail.documentUrl" :download="detail.documentUrl" ><button>下载报名表</button> <span>{{ detail.documentUrl }}</span></a></div>
                </label>
                <c-upload 
                  title="上传报名表" 
                  v-on:upload="uploadEnd($event,val.name)"
                  type="file"
                  :size="2048"
                  :nums="val['data-pics']*1"
                  :placeholder="val.placeholder">
                </c-upload>
              </div>
            </div>
            <div class="btnbox">
              <!-- <div class="btn">保存到草稿箱</div> -->
              <div class="btn" @click="checkForm(0)">保存到草稿箱</div>
              <div class="btn bgred" @click="checkForm(1)">提 交</div>
            </div>

        </el-form>
    </div>
  </div>
</template>

<script>
import cInput from './components/c-input.vue';
import cSelect from './components/c-select.vue';
import cTextarea from './components/c-textarea.vue';
import cUpload from './components/c-upload.vue';
import cBase from './components/c-base.vue';
import tableDesp from './components/table/table-desp.vue';


import {postPrizes} from '@/api/index.js';
import store from '@/store/index.js';



export default {
  name: 'f',
  components: {cInput,cSelect,cTextarea,cUpload,cBase,tableDesp},
  data(){
    return {
      form:{},
      rules:{
        bigThings:[{required: true, message: '请输入企业名称', trigger: 'blur' }],
      }
    }
  },
  created(){
    store.commit('setPrizesIdx',this.$route.query.idx);
    // console.log(this.token);
  },
  computed:{
    detail(){
      let detail = store.getters.formDetail;
      console.log(store);
      console.log(detail);
      if(detail===undefined){
        detail=JSON.parse(localStorage.detail);
      }else{
        localStorage.detail = JSON.stringify(detail);
      }
      // console.log(detail);
      this.createFormData(detail)
      return detail;
    },
    token(){
      let token = store.state.token;
      // console.log(token)
      if(token!==undefined){
        return store.state.token
      }else{
        store.commit('setLoginShow',true);
        return undefined;
      }
    },
    baseIsShow(){
      let id = this.detail.id;
      return id===18||id===19||id===20||id===21||id===33||id===34||id===35||id===36;
    }
  },
  methods:{
    uploadEnd(e,name){
      console.log(e);
      console.log(name);
      let str = '';
      e.forEach((item,index)=>{
        if(index!==0){
          str+=',';
        }
        str+=item;
      })
      this.form[name]=str;
    },
    createFormData(detail){
      let form = {};
      for(let key in detail.forms){
        form[detail.forms[key].name]='';
        if(!!detail.forms[key].files&&detail.forms[key].type==="textarea"){
          form[detail.forms[key].files.name]='';
        }
      }
      this.form = form;
      console.log(this.form);
    },
    checkForm(draft) {
      if(this.token===undefined){
        store.commit('setLoginShow',true);
        return false;
      }
      console.log(this.form);
      //保存草稿
      if(draft===0){
        this.submitForm(draft);
        return false;
      }

      // 提交报名表单校验
      let keys = Object.keys(this.form);
      for(let i=0; i<keys.length; i++){
        let val = this.form[keys[i]];
        // console.log(this.detail.forms[keys[i]]);
        //第一层表单项
        if(val===''&&this.detail.forms[keys[i]]!=undefined){
          // console.log("1级表单校验");
          alert(this.detail.forms[keys[i]].title+"不能为空!");
          return false;
        }

        //第二层表单项校验，例如重大事件表单下有二级表单数据
        // if(this.detail.forms[keys[i]]!=undefined&&this.detail.forms[keys[i]].files!=undefined){
        //   console.log(this.detail.forms[keys[i]].files);
        //   if(this.form[this.detail.forms[keys[i]].files.name]===''){
        //     // alert(this.detail.forms[keys[i]].files.title+"不能为空!");
        //     alert("请上传相关证明文件！");
        //     return false;
        //   }
        // }
        
      }
      this.submitForm(draft);
    },
    submitForm(draft){
      Object.assign(this.form,{token:this.token,award_id:this.detail.id,status:draft})

      postPrizes(this.form).then(res=>{
        // console.log(res);
        if(res.errcode===200){
          this.$message.success(res.errmsg);
          this.$router.push({ path: '/record' })
        }else{
          this.$message.error(res.errmsg);
        }
      })

    }
  }
}
</script>

<style scoped>
.title{padding:0 25px; font-size: 25px; color:#ecbf95; margin:50px 0 15px 0;}
.desp{padding:0 25px; line-height:24px; font-size:14px; color:#ecbf95;}
.desp span{color:#ecbf95;}

.form-box{margin-top:30px; padding:70px 64px; background:#4a4130; width:760px;}
.form-box>form{padding:20px 110px; background:#fff; width:540px;}
.form-box>form h2{font-size:20px; margin-top:10px; margin-bottom:30px; color:#28282a; text-align:center;}

/*.el-form{border:4px solid #eda95e;}*/

.big-box{box-sizing: border-box; width:540px; margin-bottom:10px; line-height:36px; border: 1px solid #ccc; border-radius:5px;}
.big-box .label{line-height:36px; border-bottom:1px solid #ccc; display: block; margin:0 15px 15px 15px; font-size:14px; color:#28282a; font-weight:bold; }
.big-box .label.label-flex{display:flex;}
.big-box .label.label-flex .downloadbox{margin-left:10px;}
/* .big-box .label.label-flex .downloadbox a{color:#999; } */
.big-box .label.label-flex .downloadbox button{background:#fc661f; color:#fff; border-radius: 5px; padding:5px 10px; font-size: 13px;}
.big-box .label.label-flex .downloadbox span{color:#cc0526; text-decoration: underline;}



.btnbox{display: flex; justify-content: center; margin-top:20px;}
.btn{width:120px; margin:0 10px; height:37px; line-height:37px; text-align:center; border:1px solid #240085; color:#fff; font-size:14px; border-radius:5px; cursor:pointer; background: #17213a; transition-duration: 300ms;}
.btn.bgred{background:#b50333; border:1px solid #b50333;}
.btn:hover{opacity:.7; box-shadow: 1px 1px 3px #999;}

/* .download-a{cursor: pointer; font-weight: normal; text-decoration: underline; color:red; margin-left:5px;} */


/* table{width: 100%; margin-top:5px; font-size:14px;}
table td{padding: 6px 8px; line-height:14px; text-align:center; border:1px solid #ecbf95; padding:10px 0;}
table .table-title td{font-weight: bold;} */
</style>

<style>
/*element ui样式*/
.form-box .el-input__inner{height:36px!important; line-height:36px; border:none; outline:none;}
.form-box .el-input__icon{line-height: 36px;}
input::-webkit-input-placeholder{color:#999;}
input::-moz-input-placeholder{color:#999;}
textarea::-webkit-input-placeholder{color:#999;}
textarea::-moz-input-placeholder{color:#999;}
</style>