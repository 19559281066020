import request from '@/utils/request'

export function getCode(params) {
  return request({
    url: '/login/sms',
    method: 'post',
    data: params
  })
}

export function getToken(params) {
  return request({
    url: '/login/token',
    method: 'post',
    data: params
  })
}

//所有报名奖项
export function getPrizes(params) {
  return request({
    url: '/prizes',
    method: 'get',
    params
  })
}

//提交报名
export function postPrizes(params) {
  return request({
    url: '/prizes/save',
    method: 'post',
    data: params
  })
}
//编辑报名
export function updatePrizes(params) {
  return request({
    url: '/prizes/update',
    method: 'post',
    data: params
  })
}


//报名列表
export function getPrizesList(params) {
  return request({
    url: '/prizes/list',
    method: 'post',
    data: params
  })
}

//报名详情
export function getPrizesDetail(params) {
  return request({
    url: '/prizes/show',
    method: 'post',
    data: params
  })
}

//删除报名
export function delPrizes(params) {
  return request({
    url: '/prizes/delete',
    method: 'post',
    data: params
  })
}