<template>
  <div>
   <div v-if="id===2">
        <table border="1" class="table-order">
          <tr class="table-title"><td colspan="6">创新领跑者评分标准</td></tr>
          <tr>
               <td>评判维度</td>
               <td>首创技术或产品</td>
               <td>发明专利</td>
               <td>标准制定</td>
               <td>市场占有率</td>
               <td>行业影响</td>
         </tr>
         <tr>
            <td>分值</td><td>0-20分</td><td>0-30分</td><td>0-20分</td><td>0-15分</td><td>0-15分</td>
         </tr>
        </table>
   </div>
   <div v-if="id===3">
        <table border="1" class="table-order">
          <tr class="table-title"><td colspan="6">数智化标杆企业评分指标</td></tr>
          <tr>
               <td>评判维度</td>
               <td>行业首创/率先引入</td>
               <td>数智化程度</td>
               <td>领先水平</td>
               <td>效率提升</td>
         </tr>
         <tr>
            <td>分值</td><td>0-30分</td><td>0-30分</td><td>0-20分</td><td>0-20分</td>
         </tr>
        </table>
   </div>
   <div v-if="id===12">
        <table border="1" class="table-order">
          <tr class="table-title"><td colspan="5">低碳先锋品牌评分指标</td></tr>
          <tr>
               <td>评判维度</td>
               <td>节能减碳效果</td>
               <td>先进性</td>
               <td>示范意义</td>
               <td>投入的持续性</td>
         </tr>
         <tr>
            <td>分值</td><td>0-50分</td><td>0-20分</td><td>0-15分</td><td>0-15分</td>
         </tr>
        </table>
   </div>
   <div v-if="id===19">
        <table border="1" class="table-order">
          <tr class="table-title"><td colspan="5">低碳发展先进企业评分指标</td></tr>
          <tr>
               <td>评判维度</td>
               <td>节能减碳效果</td>
               <td>先进性</td>
               <td>示范意义</td>
               <td>投入的持续性</td>
         </tr>
         <tr>
            <td>分值</td><td>0-50分</td><td>0-20分</td><td>0-15分</td><td>0-15分</td>
         </tr>
        </table>
   </div>
   <div v-if="id===20">
        <table border="1" class="table-order">
          <tr class="table-title"><td colspan="5">产品力领先企业评分指标</td></tr>
          <tr>
            <td>评判维度</td>
            <td>产品品质</td>
            <td>产品技术工艺</td>
            <td>产品市场表现</td>
            <td>行业口碑</td>
         </tr>
         <tr>
            <td>分值</td><td>0-30分</td><td>0-20分</td><td>0-30分</td><td>0-20分</td>
         </tr>
        </table>
   </div>
   <div v-if="id===21">
        <table border="1" class="table-order">
          <tr class="table-title"><td colspan="5">制造力领先企业评分指标</td></tr>
          <tr>
            <td>评判维度</td>
            <td>生产能力</td>
            <td>品质保障</td>
            <td>技术创新/产品创新</td>
            <td>口碑评价</td>
         </tr>
         <tr>
            <td>分值</td><td>0-30分</td><td>0-30分</td><td>0-20分</td><td>0-20分</td>
         </tr>
        </table>
   </div>
   <div v-if="id===22">
        <table border="1" class="table-order">
          <tr class="table-title"><td colspan="6">岩板龙头企业评分指标</td></tr>
          <tr>
            <td>评判维度</td>
            <td>生产能力</td>
            <td>品质</td>
            <td>品牌推广投入</td>
            <td>终端店面及交付能力</td>
            <td>示范作用</td>
         </tr>
         <tr>
            <td>分值</td><td>0-20分</td><td>0-20分</td><td>0-20分</td><td>0-25分</td><td>0-15分</td>
         </tr>
        </table>
   </div>
   <div v-if="id===25">
      <p>3、产品相关性能需达到以下标准：</p>
      <div style="text-indent: 15px;">
         <p>A、干压瓷质砖产品标准要求</p>
         <p>● 吸水率：平均值≤0.15%，单个值≤0.25%</p>
         <p>● 破坏强度：厚度≥7.5mm的，要求≥1500；厚度＜7.5mm,要求≥900</p>
         <p>● 断裂模数：平均值≥38，单值≥36</p>
         <p>● 耐污染性：有釉砖≥5级，无釉砖≥4级</p>
         <p>● 有釉砖抗釉裂性：经试验应无釉裂</p>
         <p>● 内照射指标：≤0.9，外照射指标≤1.2</p>
      </div>
      <div style="text-indent: 15px;">
         <p>B、陶瓷大板产品标注要求</p>
         <p>● 吸水率平均值≤0.2%，单个值≤0.3%</p>
         <p>● 破坏强度，N：h≤4mm,＞400; 4＜h≤6mm,≥800；6＜h≤7.5mm,≥1000；7.5＜h≤13.5mm,≥1600；h＞13.5mm,≥3000</p>
         <p>● 断裂模数：h≤6mm，平均值≥47，单值≥42；13.3mm＞h＞6mm,平均值≥36，单值≥33</p>
         <p>● 耐污染性：有釉板≥5级，无釉板≥4级</p>
         <p>● 莫氏硬度：有釉≥4，无釉≥5</p>
         <p>● 有釉砖抗釉裂性：经试验应无釉裂</p>
         <p>● 内照射指标：≤0.9，外照射指标≤1.2</p>
      </div>
      <p>4、必须提供产品对应的检测报告；</p>
   </div>

   <div v-if="id===28">
      <p style="font-weight: bold;">特别说明：评选期间，组委会将会对申报“原创设计”的产品进行公示，如被投诉举报侵权，经核实后将取消参评资格。</p>
   </div>

   <div v-if="id===39">
      <table border="1" class="table-order">
         <tr class="table-title"><td colspan="6">产业链隐形冠军企业评分标准</td></tr>
         <tr>
            <td>评判维度</td>
            <td>细分领域领袖</td>
            <td>产品领先水平</td>
            <td>企业经营</td>
            <td>研发投入</td>
            <td>知识产权</td>
         </tr>
         <tr>
            <td>分值</td><td>0-30分</td><td>0-20分</td><td>0-15分</td><td>0-20分</td><td>0-15分</td>
         </tr>
      </table>
   </div>

   <div v-if="id===45">
      <table border="1" class="table-order">
         <tr class="table-title"><td colspan="6">新锐榜20年高质量发展企业评分标准</td></tr>
         <tr>
            <td>评判维度</td>
            <td>效益发展</td>
            <td>创新发展</td>
            <td>绿色发展</td>
            <td>社会责任</td>
         </tr>
         <tr>
            <td>分值</td><td>0-25分</td><td>0-25分</td><td>0-25分</td><td>0-25分</td>
         </tr>
      </table>
   </div>

   <div v-if="id===47">
      <p>4、品类选择——</p>
      <div style="text-indent: 15px;">
         <p>A：大理石瓷砖、仿古砖、户外砖、木纹砖、抛光砖、哑光砖、中板、岩板(1.2*2.4M 以上）；</p>
         <p>B：智能马桶、马桶、浴室柜、淋浴房、花洒、龙头、面盆、浴缸。</p>
      </div>
   </div>

  </div>
</template>

<script>
export default {
    props:{
        id:{type:Number,default:0}
    },
    name: 'table-desp'
}
</script>

<style scoped>
p{color:#ecbf95;}
table{width: 100%; margin-top:5px; font-size:14px;}
table td{padding: 6px 8px; line-height:14px; text-align:center; border:1px solid #ecbf95; padding:10px 0; color:#ecbf95;}
table .table-title td{font-weight: bold;}
</style>
