<template>
	<div>
	<div class="table-title">产业链隐形冠军企业资料填报表（装备、材料、配套服务企业）<span v-if="!loading" class="del-storage" @click="clearStorage">清空缓存</span></div>
  	<table border="1" class="table-order" ref="table">
      	<!-- <thead>
	        <tr>
	          <th colspan="6">低碳发展先进企业（工厂）资料填报表</th>
	        </tr>
      	</thead> -->
      	<tbody>
	      	<tr>
	      		<td>企业全称</td>
	      		<td colspan="2"><input @blur="blur" type="" v-model="formdata.v0" /></td>
	      		<td>品牌名称</td>
	      		<td colspan="2"><input @blur="blur" type="" v-model="formdata.v1" /></td>
	      	</tr>
	      	<tr class="hasTextarea">
	      		<td>企业简介<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="blur($event,'v2')" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v2"></div>
	      			<!-- <textarea @blur="blur" v-model="formdata.v" class="textarea" resize="none"></textarea> -->
	      		</td>
	      	</tr>
			<tr class="hasTextarea">
	      		<td>2020-2022年<br/>创新产品描述<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="blur($event,'v3')" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v3"></div>
	      			<!-- <textarea @blur="blur" v-model="formdata.v" class="textarea" resize="none"></textarea> -->
	      		</td>
	      	</tr>
			<tr>
	      		<td class="td1">营业收入<br/>2022年</td>
	      		<td class="td2"><input @blur="blur" type="" v-model="formdata.v4" /></td>
	      		<td class="td3">研发投入<br/>2022年</td>
	      		<td class="td4"><input @blur="blur" type="" v-model="formdata.v5" /></td>
	      		<td class="td5">专利数量<br/>2020-2022年</td>
	      		<td class="td6"><input @blur="blur" type="" v-model="formdata.v6" /></td>
	      	</tr>

			<template v-for="(item,index) in zlArr" :key="index">
				<tr v-if="index===0" class="subtitle">
					<td colspan="6">
						<span>企业独创工艺、技术、产品或特色化服务<span v-if="!loading">（如有多项，可增加页数）</span></span>
						<button v-if="!loading"  class="btn" @click="addPro(1)">点击新增专利产品</button>
					</td>
				</tr>
				<tr class="bg1">
					<td class="td1">独创产品({{index+1}})<button v-if="index!==0&&!loading" class="btn del" @click="del(1,index)">删除</button></td>
					<td class="td2"><input @blur="blur" type="" v-model="formdata['v7_'+index]" /></td>
					<td class="td3">推出时间</td>
					<td class="td4"><input @blur="blur" type="" v-model="formdata['v8_'+index]" /></td>
					<td class="td5">销售数量</td>
					<td class="td6" ><input @blur="blur" type="" v-model="formdata['v9_'+index]" /></td>
				</tr>
				<tr class="bg1">
					<td colspan="1">代表客户</td>
					<td colspan="5"><input @blur="blur" type="" v-model="formdata['v10_'+index]" /></td> 
				</tr>
				<tr class="hasTextarea bg1">
					<td>设备领先性介绍<br/>(500字以内）</td>
					<td colspan="5">
						<div @blur="blur($event,'v11_'+index)" @input="input($event,'v11_'+index)" name="v11" @paste="paste" class="textarea" contenteditable="true" v-html="formdata['v11_'+index]"></div>
					</td>
				</tr>
			</template>

	      	<tr class="hasTextarea">
	      		<td>其它说明<br/>(500字以内）</td>
	      		<td colspan="5">
	      			<div @blur="blur($event,'v12')" name="v12" @paste="paste" class="textarea" contenteditable="true" v-html="formdata.v12"></div>
	      		</td>
	      	</tr>

    	</tbody>
    </table>
	</div>
</template>

<script>
import { ref, reactive,onMounted,onUpdated, computed,toRaw} from 'vue'
import { ElMessageBox} from 'element-plus'

  export default{
    name: 'table4',
	props:{loading:{type:Boolean,default:false}},
    setup(props,{emit}){
    	let ctable7 = {};
    	if(!!localStorage.getItem('ctable7')){
    		ctable7 = JSON.parse(localStorage.getItem('ctable7'))
    	}else{
    		for(let i=0;i<17; i++){
    			ctable7['v'+i] = ''
    		}
    		console.log(ctable7);
    	}
    	const formdata = reactive(ctable7);


    	const table = ref();
    	const blur = (e,name)=>{
    		console.log(e,name);

			let nodeList = table.value.querySelectorAll('tr');
			let sumHeight = 0;
			for(let i=0; i<nodeList.length; i++){
				let trHeight = window.getComputedStyle(nodeList[i]).height.replace("px","")*1;
				sumHeight+=trHeight;
				//textarea自适应高度
				// if(nodeList[i].className==='hasTextarea'){
				// 	let textarea = nodeList[i].querySelectorAll('.textarea')[0];
				// 	textarea.style.height = "inherit";
				// 	textarea.style.height = textarea.scrollHeight+'px';
				// }
				console.log(sumHeight)
			}

			if(!!name){
				let targetElem = e.target;
				//删掉多余空行
				targetElem.innerHTML = targetElem.innerHTML.replace(/<div><br><\/div>/g, '');
				formdata[name] = targetElem.innerHTML;
				console.log(targetElem.innerHTML)
			}
			localStorage.setItem("ctable7", JSON.stringify(toRaw(formdata)));
			// emit("dinput",toRaw(formdata));
    	}
    	const placeholderShow = ref(true);
    	const input = (e,name)=>{
    		if(e.target.innerHTML===""){
    			placeholderShow.value = true;
    		}else{
    			placeholderShow.value = false;
    		}
			// formdata[name] = e.target.innerHTML;
			// e.target.selectionStart = e.target.innerHTML.length;

    	}
    	const paste = (e)=>{
    		e.preventDefault();
	        var text = null;
	    
	        if(window.clipboardData && clipboardData.setData) {
	            // IE
	            text = window.clipboardData.getData('text');
	        } else {
	            text = (e.originalEvent || e).clipboardData.getData('text/plain') || prompt('在这里输入文本');
	        }
	        if (document.body.createTextRange) {    
	            if (document.selection) {
	                textRange = document.selection.createRange();
	            } else if (window.getSelection) {
	                sel = window.getSelection();
	                var range = sel.getRangeAt(0);
	                
	                // 创建临时元素，使得TextRange可以移动到正确的位置
	                var tempEl = document.createElement("span");
	                tempEl.innerHTML = "&#FEFF;";
	                range.deleteContents();
	                range.insertNode(tempEl);
	                textRange = document.body.createTextRange();
	                textRange.moveToElementText(tempEl);
	                tempEl.parentNode.removeChild(tempEl);
	            }
	            textRange.text = text;
	            textRange.collapse(false);
	            textRange.select();
	        } else {
	            // Chrome之类浏览器
	            document.execCommand("insertText", false, text);
	        }
    	}

		const zlArr = ref([1])
		if(!!localStorage.getItem('zlArr')){
    		zlArr.value = JSON.parse(localStorage.getItem('zlArr'))
    	}else{
			zlArr.value = [1];
		}
		const proArr = ref([1])
		if(!!localStorage.getItem('proArr')){
    		proArr.value = JSON.parse(localStorage.getItem('proArr'))
    	}else{
			proArr.value = [1];
		}
		const addPro = (type)=>{
			if(type===1){
				zlArr.value.push(1);
				localStorage.setItem("zlArr", JSON.stringify(toRaw(zlArr.value)));
			}else{
				proArr.value.push(1);
				localStorage.setItem("proArr", JSON.stringify(toRaw(proArr.value)));
			}
		}
		const del = (type,idx)=>{
			if(type===1){
				zlArr.value.splice(idx,1);
				localStorage.setItem("zlArr", JSON.stringify(toRaw(zlArr.value)));
			}else{
				proArr.value.splice(idx,1);
				localStorage.setItem("proArr", JSON.stringify(toRaw(proArr.value)));
			}
		}
		const clearStorage = ()=>{
			ElMessageBox.alert('是否清空表单缓存？', '清空缓存', {
				confirmButtonText: '确定',
				callback: (action) => {
					console.log(action);
					if(action==='confirm'){
						localStorage.removeItem("zlArr");
						localStorage.removeItem("proArr");
						localStorage.removeItem("cTable7");
						zlArr.value = [1];
						proArr.value = [1];
						Object.keys(formdata).forEach(function(key){
							formdata[key] = '';
						});
					}
				},
			})
		}

    	return {
    		blur,
    		input,
    		table,
    		paste,
    		formdata,
    		placeholderShow,
			addPro,
			del,
			zlArr,
			proArr,
			clearStorage
    	}
    }
  }
</script>

<style scoped>
.table-title{font-weight: bold;}
table{width: 100%; margin-top:5px; font-size:14px;}
table thead{background:#f6f6f6;}
table thead th{padding: 5px 5px;}
table .tr-title{font-weight: bold;}

table td{padding: 6px 8px; line-height:14px;}
table td:nth-of-type(1){width:90px; line-height: 18px;}
/*table td:nth-of-type(3){width:120px; line-height: 18px;}*/

table td:nth-of-type(2n+1){text-align: center;}
table td input{width:100%; border:1px solid #ddd; padding:5px 5px; box-sizing: border-box;}
table td input:focus {outline:none; border: 1px solid #b70038;}
table td textarea{width:100%; border:1px solid #ddd; padding:5px 5px; box-sizing: border-box;}
table td textarea:focus {outline:none; border: 1px solid #b70038;}
table td.td1{width:120px;}
.textarea{width: 100%; min-height:100px; margin-left: auto;margin-right: auto;outline: 0;border: 1px solid #ddd;line-height: 22px;padding:5px 5px;word-wrap: break-word;
overflow-x: hidden;overflow-y: auto;box-sizing: border-box; resize:none; overflow: hidden; transition: all 0.2s linear; background:#fff;}
.textarea:focus{outline:none; border: 1px solid #b70038;}


.inherit-input{text-align: center;}
.inherit-input input{width:60px; border:none; border-bottom:1px solid #666; text-align: center; margin:0 4px;}
.inherit-input input:focus{outline:none; border:none; border-bottom: 1px solid #b70038;}

.placeholder{position:absolute; left:0; right:0; top:50%; margin-top:-9px; text-align: center; z-index: 8; color:#999;}

.btn{padding:5px 10px; text-align:center; color:#fff; font-size:12px; border-radius:5px; cursor:pointer; background: #17213a;
    border: 1px solid #240085; transition-duration: 300ms; margin:0 5px;}
.btn.del{background: none;border:none; color:#b70038; text-decoration: underline;}

.bg1{background: #f3f4ff;}
.bg2{background: #fbf5ef;}

.subtitle{font-weight: bold; color:#111; background:#f1f1f1;}
span.del-storage{color:#b70038; text-decoration: underline; cursor: pointer;}
</style>
