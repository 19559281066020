import axios from 'axios'
import { ElMessage,ElMessageBox } from 'element-plus'
import vue from '../main.js'
// import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL:'https://20thxrbadmin.fstcb.com/api/v1/',
  // baseURL:'http://2020xrb.fstcb.com/api/v1',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})
// console.log(process.env.VUE_APP_BASE_API);
// request interceptor request拦截器
service.interceptors.request.use(
  config => {
    // do something before request is sent

    // if (store.getters.token) {
      // console.log("request拦截器");
      // 让每个请求携带token--['X-Token']为自定义key
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['X-Token'] = getToken()
    // }
    return config
  },
  error => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    const res = response.data
    // if(res.errcode===1&&res.errmsg==='验证信息有误'){
    if(res.errcode===401&&res.errmsg==='请求令牌不正确'){
      ElMessageBox({
        message: '登录失效，请重新登录!',
        type: 'error',
        callback(){
         localStorage.removeItem("token");
         localStorage.clear();
         window.location.reload()
          // vue.$store.commit('setToken',undefined);
          // vue.$router.replace('/index');
          // setTimeout(()=>{
          //   vue.$store.commit('setLoginShow',true);
          // },1200)
        }
      })
      return false;
    }
    if(response.status===200){
      return res
    }else{
      ElMessage({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    }
  },
  error => {
    // console.log('err' + error)
    return Promise.reject(error)
  }
)

export default service
