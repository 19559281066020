<template>
  <div class="apply">

    <img src="@/assets/liucheng.jpg?temp=0229" style="margin-bottom:15px;">

  
    <div style="color:#eed3a6; font-size:20px;">在线报名</div>
    <div style="color:#e5c191; font-size:18px; font-weight:bold; margin-top:5px;">请点击奖项进入报名</div>
    <div class="nav-box">
      <!-- <div 
        v-for="(item,index) in prizes" 
        :key="index" :to="'/form?idx='+index" 
        :title="item.name"
        @click="setIdx(index)">
          {{index+1}}.{{item.name}}
      </div> -->
      <div class="nav-box">
        <div class="ntitle"><div>01 - 全产业链</div></div>
        <div class="wrap" v-for="(item,index) in list" :key="index">
          <div class="typebox"><img :src="require('@/assets/'+ item.icon)"><div>{{item.name}}</div></div>
          <div class="btnbox">
            <div class="btn" v-for="(subitem,subindex) in item.lists" :key="subindex" @click="setIdx(subitem.id)">
              <div class="ell">{{subitem.name}}</div>
              <div v-if="subitem.subname!=''">{{subitem.subname}}</div>
              <div v-if="!!subitem.subname2">{{subitem.subname2}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="nav-box">
        <div class="ntitle"><div>02 - 瓷砖卫浴企业</div></div>
        <div class="wrap" v-for="(item,index) in list2" :key="index">
          <div class="typebox"><img :src="require('@/assets/'+ item.icon)"><div>{{item.name}}</div></div>
          <div class="btnbox">
            <div class="btn" v-for="(subitem,subindex) in item.lists" :key="subindex" @click="setIdx(subitem.id)">
              <div class="ell">{{subitem.name}}</div>
              <div v-if="subitem.subname!=''">{{subitem.subname}}</div>
              <div v-if="!!subitem.subname2">{{subitem.subname2}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="nav-box">
        <div class="ntitle"><div>03 - 装备材料类/上下游</div></div>
        <div class="wrap" v-for="(item,index) in list3" :key="index">
          <div class="typebox"><img :src="require('@/assets/'+ item.icon)"><div>{{item.name}}</div></div>
          <div class="btnbox">
            <div class="btn" v-for="(subitem,subindex) in item.lists" :key="subindex" @click="setIdx(subitem.id)">
              <div class="ell">{{subitem.name}}</div>
              <div v-if="subitem.subname!=''">{{subitem.subname}}</div>
              <div v-if="!!subitem.subname2">{{subitem.subname2}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="nav-box">
        <div class="ntitle"><div>“致敬经典” 新锐榜20年特别贡献奖</div></div>
        <div class="wrap" v-for="(item,index) in list4" :key="index">
          <div class="typebox"><img :src="require('@/assets/'+ item.icon)"><div>{{item.name}}</div></div>
          <div class="btnbox">
            <div class="btn last" v-for="(subitem,subindex) in item.lists" :key="subindex" @click="setIdx(subitem.id)">
              <div class="ell">{{subitem.name}}</div>
              <div v-if="subitem.subname!=''">{{subitem.subname}}</div>
              <div v-if="!!subitem.subname2">{{subitem.subname2}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="contact-box">
        <div>联系我们</div>
        <div>
          <div>135 0996 0102 李先生</div>
          <div>135 7971 0139 曾小姐</div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import store from '@/store/index.js';
import router from '@/router/index.js';

export default {
  name: '',
  components: {},
  data(){
    return {
      list:[
        {name:"全",icon:'nav5.png',
          lists:[
            {id:1,name:"金土奖",subname:""},
            {id:2,name:"创新引领者",subname:""},
            {id:3,name:"数智化标杆企业",subname:""},
            {id:4,name:"科技创新奖",subname:"技术或产品"},
            {id:5,name:"服务质量奖",subname:"金奖/优秀奖"},
            {id:6,name:"年度产品—瓷砖类",subname:"金奖/优秀奖"},
            {id:7,name:"年度产品-卫浴类",subname:"金奖/优秀奖"},
            {id:8,name:"年度产品-装备材料类",subname:"金奖/优秀奖"},
            {id:9,name:"年度产品-其他类别",subname:"金奖/优秀奖"},
          ]
        }
      ],
      list2:[
        {name:"品牌",icon:'nav0.png',
          lists:[
            {id:10,name:"陶瓷领军品牌",subname:""},
            {id:11,name:"卫浴领军品牌",subname:""},
            {id:12,name:"低碳先锋品牌",subname:""},
            {id:13,name:"年度影响力品牌",subname:"金奖/优秀奖"},
            {id:14,name:"新锐品牌",subname:"金奖/优秀奖"},
            {id:15,name:"设计师喜爱品牌",subname:""},
            {id:16,name:"空间定制先锋品牌",subname:""},
            {id:17,name:"智能卫浴标杆品牌",subname:""},
            {id:18,name:"家居焕新推荐品牌",subname:""},
          ]
        },
        {name:"企业",icon:'nav1.png',
          lists:[
          {id:19,name:"低碳发展先进企业",subname:""},
          {id:20,name:"产品力领先企业",subname:""},
          {id:21,name:"制造力领先企业",subname:""},
          {id:22,name:"岩板龙头企业",subname:""},
          {id:23,name:"区域龙头企业",subname:""},
          {id:24,name:"社会责任企业",subname:""},
        ]},
        {name:"产品",icon:'nav3.png',lists:[
          {id:25,name:"品质金奖",subname:""},
          {id:26,name:"国潮精品",subname:""},
          {id:27,name:"年度精品",subname:""},
          {id:28,name:"原创设计产品",subname:"金奖/优秀奖"},
          {id:29,name:"技术工艺领先产品",subname:""},
          {id:30,name:"质感美学奖",subname:"金奖/优秀奖"},
          {id:31,name:"抗菌卫浴产品",subname:"金奖/优秀奖"},
          {id:32,name:"岩板金奖/岩板优秀奖",subname:""},
          {id:33,name:"设计师喜爱产品",subname:""},
          {id:34,name:"工程用户喜爱产品",subname:""},
          {id:35,name:"家装用户喜爱产品",subname:""},
        ]},
        {name:"空间",icon:'nav6.png',lists:[
          {id:36,name:"品牌展厅",subname:"金奖/优秀奖"},
          {id:37,name:"品牌专卖店/品牌运营中心",subname:"金奖/优秀奖"},
        ]}
      ],
      list3:[
        {name:"企业",icon:'nav1.png',lists:[
          {id:38,name:"全球化标杆企业",subname:""},
          {id:39,name:"产业链隐形冠军企业",subname:""},
          {id:40,name:"金牌供应服务商",subname:""},
         
        ]},
        {name:"产品",icon:'nav3.png',lists:[
          {id:41,name:"智能制造先进装备",subname:"金奖/优秀奖"},
          {id:42,name:"技术工艺领先产品",subname:"金奖/优秀奖"},
          {id:43,name:"低碳节能先进技术",subname:"金奖/优秀奖"},
          {id:44,name:"绿色环保创新产品",subname:"金奖/优秀奖"}
        ]}
      ],
      list4:[
        {name:"企业",icon:'nav1.png',lists:[
          {id:45,name:"新锐榜20年",subname:"高质量发展企业"},
          {id:46,name:"新锐榜20年",subname:"卓越品牌"},
          {id:47,name:"新锐榜20年",subname:"品类发展引领者"},
          {id:48,name:"新锐榜20年",subname:"杰出原创IP产品奖"},
          {id:49,name:"新锐榜20年",subname:"经典产品成就奖"}
        ]}
      ]
    }
  },
  computed:{
    token(){return store.state.token},
    prizes(){return store.state.prizes}
  },
  created(){
    // store.dispatch('getPrizes');
    console.log(store.state.prizes)
    if(this.token===undefined){
        store.commit('setLoginShow',true);
    }
  },
  methods:{
    setIdx(idx){
      console.log(this.token);
      if(this.token!=undefined){
        store.commit('setPrizesIdx',idx);
        console.log(router);
        this.$router.push({ path: '/form', query: { idx: idx } })
        // this.$router.push({ path: `/form?idx=${idx}` })
      }else{
        store.commit('setLoginShow',true);
        //设置登录成功后需要跳转的页面路径
      }
    }
  }
}
</script>

<style scoped>
.apply{padding:0px 0 0 0px;}

.sub-title{margin-top:35px; font-size:20px; color:#8693e6;}
.sub-title>span{color:#fff; font-weight: bold;}

/*.nav-box{width:830px; margin:20px auto 100px auto; display:flex; flex-wrap: wrap;}
.nav-box div{width:264px; height:50px; line-height:50px; margin:5px 6px; text-align:left; text-indent:10px; color:#5f330e; background:#ffd0a2; transition-duration: 300ms; border-radius:6px; font-size:15px; cursor:pointer;}
.nav-box div:hover{color:#fff; background:#b50007; box-shadow: 0 0 7px #000;}*/

.nav-box{width:1000px; margin:50px 20px 50px -15px;}
.nav-box .wrap{display:flex; margin-bottom:28px;}
.nav-box .typebox{flex:0 0 45px; width:45px; text-align: center; color:#fff; font-size:14px; padding-top:3px; margin-right:12px;}
.nav-box .typebox img{width:35px; height:35px; }
.nav-box .typebox div{margin-top:0px;}
.nav-box .btnbox{display:flex; flex-wrap: wrap;}
.nav-box .btnbox .btn{flex:0 0 206px; width:206px; min-height:30px; padding:10px 0; background:#e6c38b; color:#000; border-radius: 5px; margin-right:12px; margin-bottom:12px; cursor:pointer;
  display:flex; flex-direction: column; justify-content: center; align-items: center;}
.nav-box .btnbox .btn:nth-of-type(4n){margin-right:0;}
.nav-box .btnbox .btn:hover{background:#b7002e; color:#fff;}
.nav-box .btnbox .btn>div:nth-of-type(1){font-size:16px; max-width: 230px; }
.nav-box .btnbox .btn>div:nth-of-type(2){font-size:12px; }
.nav-box .btnbox .btn>div:nth-of-type(3){font-size:11px;}

.nav-box .btnbox .btn.last>div:nth-of-type(2){font-size:16px; }

.nav-box .ntitle{width:770px; height:102px; margin:20px auto; display:flex; background: url(../../assets/ntitle-bg.png) no-repeat; background-size:100% 100%;}
.nav-box .ntitle div{margin:auto; font-size:20px; color:#e1bb8e; font-weight: bold;}

.contact-box{width: 491px; height:86px; margin:30px auto; border:2px solid #e0c08d; display:flex; align-items: center; color:#e0c08d; position:relative;}
.contact-box::after{content:''; display:block; position: absolute; top:98px; left:-2px; width:39px; height:7px; border:2px solid #e0c08d; display:flex;}
.contact-box>div:nth-of-type(1){font-size:16px; margin-left:30px; margin-right:180px;}
.contact-box>div:nth-of-type(2) div{padding:2px 0; font-size:13px;}
</style>