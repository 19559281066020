<template>
    <div class="big-box">
        <label class="label" for="name">基本资料</label>
		<ul class="img-box" v-if="fs.length>0">
			<li>
				<img :src="'https://19thxrbadmin.fstcb.com/'+fs[0]" alt="" @click="zoom(0)">
				<div class="del" @click="del(0)" v-if="!isPreview"><el-icon><close-bold /></el-icon></div>
			</li>
		</ul>
        <div class="btn-box" v-if="!isPreview">
          <div class="btn bgred w150" @click="openDialog">{{progress==='0%'?'编辑基本资料':progress}}</div>
        </div>
        <el-dialog
		    v-model="dialogVisible"
		    title="基础资料"
		    width="30%"
		    :fullscreen="true"
		    destroy-on-close
		    @close="closeDialog"
		>
			<div class="export-page page1" id="exportPage" ref="exportPage">
				<c-table v-if="formid===18" :loading="loading"></c-table>
				<c-table2 v-if="formid===19" :loading="loading"></c-table2>
				<c-table3 v-if="formid===20" :loading="loading"></c-table3>
				<c-table4 v-if="formid===21" :loading="loading"></c-table4>
				<c-table5 v-if="formid===33" :loading="loading"></c-table5>
				<c-table6 v-if="formid===34" :loading="loading"></c-table6>
				<c-table7 v-if="formid===35" :loading="loading"></c-table7>
				<c-table8 v-if="formid===36" :loading="loading"></c-table8>
            </div>

            <div class="efooter">
	            <el-button class="btn bgfff" @click="closeDialog">取消</el-button>
	            <el-button class="btn" @click="download(1)" :loading="loading">
	            	预览
	            </el-button>
	            <!-- <el-button class="btn" @click="download(2)" :loading="loading">
	            	下载到电脑
	            </el-button> -->
	            <el-button class="btn bgred" @click="download(3)" :loading="loading">
	            	保存资料
	            </el-button>
            </div>
		</el-dialog>
    </div>
</template>

<script>
import { ref, nextTick, reactive,onMounted,onUpdated, computed,toRaw} from 'vue'
import html2canvas from 'html2canvas';
import {dataURItoBlob} from '@/utils/diy-use.js';
import store from '@/store/index.js'
import axios from 'axios'
import { CloseBold } from '@element-plus/icons-vue'

// id===18||id===19||id===20||id===21||id===33||id===34||id===35||id===36
import cTable from './table/c-table';
import cTable2 from './table/c-table2';
import cTable3 from './table/c-table3';
import cTable4 from './table/c-table4';
import cTable5 from './table/c-table5';
import cTable6 from './table/c-table6';
import cTable7 from './table/c-table7';
import cTable8 from './table/c-table8';



export default {
  name: 'cbase',
  components:{CloseBold,cTable,cTable2,cTable3,cTable4,cTable5,cTable6,cTable7,cTable8},
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    modelValue: String,
    title: String,
	isPreview:{type:Boolean,default:false},
	files:{
      type:Array,default:()=>{return []}
    },
	formid:Number
  },
  emits: ['update:modelValue'],
  methods:{
  
  },
  setup(props,{emit}){
  	const dialogVisible = ref(false);
  	const loading = ref(false);
  	const progress = ref("0%");
  	const exportPage = ref(null);
	const fs = ref([]);
	fs.value = props.files;

  	const openDialog = ()=>{
  		document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  		dialogVisible.value = true;
  	}
  	const closeDialog = () =>{
  		dialogVisible.value = false;
  		document.getElementsByTagName('html')[0].style.overflow = 'auto';
  	}
  	const download = async (type)=>{
        // await nextTick();
		loading.value = true;

		setTimeout(()=>{

        let epHeight = window.getComputedStyle(exportPage.value).height.replace("px","")*1;
        html2canvas(document.getElementById('exportPage'),{
            width:1239.5,
            height:epHeight,
            scale:2
        })
        .then(function(canvas) {
			console.log(fs.value);
        	loading.value = false;
            // document.body.appendChild(canvas);
            let imgBase64 = canvas.toDataURL();
            let imgBlob = dataURItoBlob(imgBase64);
            let a = document.createElement('a');
            a.setAttribute('href', URL.createObjectURL(imgBlob));
            a.setAttribute('target', '_blank');
            a.setAttribute('id', 'downloadExport');
            console.log(imgBlob);
            // type===2&&a.setAttribute('download', ''); // 下载保存
            if(type===1||type===2){
            	type===2&&a.setAttribute('download', '');
				if(document.getElementById('downloadExport')) {
	                document.body.removeChild(document.getElementById('downloadExport'));
	            }
	            document.body.appendChild(a);
	            a.click();
	            URL.revokeObjectURL(a.href);
            }else if(type==3){
            	closeDialog(); //关闭弹出层
            	//上传到服务器
            	let formData = new FormData();
			    formData.append('token',store.state.token);
			    formData.append('filesNum',1);
			    formData.append('fileName','bigThingsFiles');
			    formData.append('bigThingsFiles0',imgBlob);
		      	let config = {
			        headers:{'Content-Type':'multipart/form-data'},
			        onUploadProgress: progressEvent => {
			            var complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
			            console.log(complete)
			            if(complete==="100%"){
			              complete = '处理中...';
			            }
			            progress.value = complete;
			        }
		      	};
			    axios.post('https://19thxrbadmin.fstcb.com/api/v1/upload/file',formData, config)
				.then(res=>{
				    console.log(res);
					console.log(fs.value);
			        setTimeout(()=>{
			          	progress.value="0%";
			        },500);
			        //errcode: 1  errmsg: "验证信息有误"
			        if(res.data.errcode===0){
						console.log(fs);
						console.log(fs.value);
						fs.value[0] = res.data.data.urls;
						// fs.value.push(res.data.data.urls);
			         	// this.fs.push(res.data.data.urls);
			         	emit('upload',fs.value);
			        }else{
			         	this.$message.error(res.data.errmsg);
			         	if(res.data.errmsg==="验证信息有误"){
			            	this.$store.commit('setLoginShow',true);
			         	}
			        }
			    }) 
            }
	
			},1000)

        });
    }
	const del = (index)=>{
		fs.value.splice(index, 1);
      	emit('upload',fs);
	}
	const zoom = (index)=>{
      store.commit("setZoomIdx",index);
      store.commit("setZoomList",fs.value);
      store.commit("setZoomVisible",true);
    }
  	return {
  		dialogVisible,
  		openDialog,
  		closeDialog,
  		loading,
  		download,
  		progress,
  		exportPage,
		fs,
		del,
		zoom
  	}
  }
}
</script>

<style scoped>
html{overflow: hidden;}
.label{line-height:36px; border-bottom:1px solid #ccc; display: block; margin:0 15px 15px 15px; font-size:14px; color:#28282a; font-weight:bold; }
.btn-box{margin: 0px 15px 15px 15px;}
.btn{padding:0 10px; height:34px; line-height:34px; text-align:center; border:1px solid #240085; color:#fff; font-size:14px; border-radius:5px; cursor:pointer; background: #17213a; transition-duration: 300ms; }
.btn.w150{width:90px;}
.btn.bgred{background:#b70038; border:1px solid #b70038;}
.btn.bgfff{background:#fff; color:#333; border:1px solid #ccc;}

.btn:hover{opacity:.7; box-shadow: 1px 1px 3px #999;}

/* 导出页面 A4纸分辨率 2479x3508 */
.export-page{padding:20px 30px 30px 30px; width:1239.5px; min-height:1754px; font-size:16px; margin:auto; color:#333; background:#fff; box-sizing: border-box; margin-bottom:15px;}

.efooter{position:absolute; bottom:0; left:0; right:0; height:80px; line-height:80px; 
display:flex; justify-content: center; align-items: center; box-shadow: 1px -2px 8px #ddd;
 border-top:1px solid #eee; background:#fff;}
 .efooter button{margin:0 5px;}

.img-box{display:flex; flex-wrap: wrap; margin:10px 15px;}
.img-box li{display:flex; width:100px; height:100px; margin:0 5px 5px 0; position: relative; background:#f1f1f1;}
.img-box li img{max-width:100px; max-height:100px; margin:auto; cursor:pointer;}
.img-box li .del{position: absolute; top:0; right:0; width:20px; height:20px; line-height:20px; text-align:center; background:#eee; cursor:pointer;}
.img-box li .del:hover{background:#ccc;}

</style>

<style>
/* 订单导出弹出层样式 */
.el-overlay{max-width: 100%; top: 0; padding-bottom: 0; margin: 0;}
.el-overlay .el-dialog.is-fullscreen{background: #eee; display: flex;flex-direction: column; height: calc(100vh); }
.el-overlay .el-dialog .el-dialog__header{height:25px; background: #fff; padding-top: 15px; padding-bottom: 15px; box-shadow: 1px 2px 5px #ddd; z-index:999;}
.el-overlay .el-dialog .el-dialog__header .el-dialog__headerbtn{font-size:25px;}
.el-overlay .el-dialog__body{flex:1; overflow-y: auto; padding-top:15px; padding-bottom:100px;}
</style>

