import router from './router'
// import store from './store'
import NProgress from 'nprogress' //进度条
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })

router.beforeEach(async(to, from, next) => {
  NProgress.start()

  const hasToken = true

  if (hasToken) {
    NProgress.done()
    next()
  } else {
    next({ path: '/index' })
    NProgress.done()
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})