<template>
  <div class="m-header">
    <div class="header"><img src="@/assets/m-header-bg.jpg" alt=""></div>
  </div>
</template>

<script>
export default {
  name: 'm-header'
}
</script>

<style scoped>
.header{font-size:0; width:100%; height:180px;}
.header img{width:100%; height:180px;}
</style>
