/* eslint-disable */
<template>
  <div>
    <div class="nav" ref="menu">
      <div :class="{'che':idx===0}" @click="scrollTo($event,348,0)"><text>全产业链</text></div>
      <div :class="{'che':idx===1}" @click="scrollTo($event,657,1)"><text>瓷砖卫浴企业</text></div>
      <div :class="{'che':idx===2}" @click="scrollTo($event,2000,2)"><text>装备材料类/上下游</text></div>
    </div>

    <img src="@/assets/liucheng-m.jpg?temp=0229" style="width:100%; margin-bottom:16px;">

    <div class="tips">请点击奖项，查看报名规则</div>


    <!-- <ul class="left-nav" ref="leftNav">
    	<li :class="{'che':idx===0}" @click="scrollTo($event,169,0)"><a>品牌</a></li>
    	<li :class="{'che':idx===1}" @click="scrollTo($event,497,1)"><a>企业</a></li>
    	<li :class="{'che':idx===2}" @click="scrollTo($event,886,2)"><a>人物</a></li>
    	<li :class="{'che':idx===3}" @click="scrollTo($event,1006,3)"><a>产品</a></li>
    	<li :class="{'che':idx===4}" @click="scrollTo($event,1540,4)"><a>岩板</a></li>
    	<li :class="{'che':idx===5}" @click="scrollTo($event,1985,5)"><a>金土奖</a></li>
    </ul> -->

    <div class="nav-box">
      <div class="ntitle"><div>01 - 全产业链</div></div>
      <div class="wrap" v-for="(item,index) in list" :key="index">
        <div class="typebox"><img :src="require('@/assets/'+ item.icon)"><div>{{item.name}}</div></div>
        <div class="btnbox">
          <div class="btn" v-for="(subitem,subindex) in item.lists" :key="subindex" @click="setIdx(subitem.id)">
            <div class="ell">{{subitem.name}}</div>
            <div v-if="subitem.subname!=''">{{subitem.subname}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="nav-box">
      <div class="ntitle"><div>02 - 瓷砖卫浴企业</div></div>
      <div class="wrap" v-for="(item,index) in list2" :key="index">
        <div class="typebox"><img :src="require('@/assets/'+ item.icon)"><div>{{item.name}}</div></div>
        <div class="btnbox">
          <div class="btn" v-for="(subitem,subindex) in item.lists" :key="subindex" @click="setIdx(subitem.id)">
            <div class="ell">{{subitem.name}}</div>
            <div v-if="subitem.subname!=''">{{subitem.subname}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="nav-box">
      <div class="ntitle"><div>03 - 装备材料类/上下游</div></div>
      <div class="wrap" v-for="(item,index) in list3" :key="index">
        <div class="typebox"><img :src="require('@/assets/'+ item.icon)"><div>{{item.name}}</div></div>
        <div class="btnbox">
          <div class="btn" v-for="(subitem,subindex) in item.lists" :key="subindex" @click="setIdx(subitem.id)">
            <div class="ell">{{subitem.name}}</div>
            <div v-if="subitem.subname!=''">{{subitem.subname}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="nav-box">
      <div class="ntitle"><div>“致敬经典” 新锐榜20年特别贡献奖</div></div>
      <div class="wrap" v-for="(item,index) in list4" :key="index">
        <div class="typebox"><img :src="require('@/assets/'+ item.icon)"><div>{{item.name}}</div></div>
        <div class="btnbox">
          <div class="btn" v-for="(subitem,subindex) in item.lists" :key="subindex" @click="setIdx(subitem.id)">
            <div class="ell">{{subitem.name}}</div>
            <div v-if="subitem.subname!=''">{{subitem.subname}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="contact-box">
        <div>联系我们</div>
        <div>
          <div>135 0996 0102 李先生</div>
          <div>135 7971 0139 曾小姐</div>
        </div>
    </div>

  </div>
</template>

<script>

export default {
  name: '',
  components: {},
  data(){
    return {
    	idx:0,
      list:[
        {name:"全",icon:'nav5.png',
          lists:[
            {id:1,name:"金土奖",subname:""},
            {id:2,name:"创新引领者",subname:""},
            {id:3,name:"数智化标杆企业",subname:""},
            {id:4,name:"科技创新奖",subname:"技术或产品"},
            {id:5,name:"服务质量奖",subname:"金奖/优秀奖"},
            {id:6,name:"年度产品—瓷砖类",subname:"金奖/优秀奖"},
            {id:7,name:"年度产品-卫浴类",subname:"金奖/优秀奖"},
            {id:8,name:"年度产品-装备材料类",subname:"金奖/优秀奖"},
            {id:9,name:"年度产品-其他类别",subname:"金奖/优秀奖"},
          ]
        }
      ],
      list2:[
        {name:"品牌",icon:'nav0.png',
          lists:[
            {id:10,name:"陶瓷领军品牌",subname:""},
            {id:11,name:"卫浴领军品牌",subname:""},
            {id:12,name:"低碳先锋品牌",subname:""},
            {id:13,name:"年度影响力品牌",subname:"金奖/优秀奖"},
            {id:14,name:"新锐品牌",subname:"金奖/优秀奖"},
            {id:15,name:"设计师喜爱品牌",subname:""},
            {id:16,name:"空间定制先锋品牌",subname:""},
            {id:17,name:"智能卫浴标杆品牌",subname:""},
            {id:18,name:"家居焕新推荐品牌",subname:""},
          ]
        },
        {name:"企业",icon:'nav1.png',
          lists:[
          {id:19,name:"低碳发展先进企业",subname:""},
          {id:20,name:"产品力领先企业",subname:""},
          {id:21,name:"制造力领先企业",subname:""},
          {id:22,name:"岩板龙头企业",subname:""},
          {id:23,name:"区域龙头企业",subname:""},
          {id:24,name:"社会责任企业",subname:""},
        ]},
        {name:"产品",icon:'nav3.png',lists:[
          {id:25,name:"品质金奖",subname:""},
          {id:26,name:"国潮精品",subname:""},
          {id:27,name:"年度精品",subname:""},
          {id:28,name:"原创设计产品",subname:"金奖/优秀奖"},
          {id:29,name:"技术工艺领先产品",subname:""},
          {id:30,name:"质感美学奖",subname:"金奖/优秀奖"},
          {id:31,name:"抗菌卫浴产品",subname:"金奖/优秀奖"},
          {id:32,name:"岩板金奖/岩板优秀奖",subname:""},
          {id:33,name:"设计师喜爱产品",subname:""},
          {id:34,name:"工程用户喜爱产品",subname:""},
          {id:35,name:"家装用户喜爱产品",subname:""},
        ]},
        {name:"空间",icon:'nav6.png',lists:[
          {id:36,name:"品牌展厅",subname:"金奖/优秀奖"},
          {id:37,name:"品牌专卖店/品牌运营中心",subname:"金奖/优秀奖"},
        ]}
      ],
      list3:[
        {name:"企业",icon:'nav1.png',lists:[
          {id:38,name:"全球化标杆企业",subname:""},
          {id:39,name:"产业链隐形冠军企业",subname:""},
          {id:40,name:"金牌供应服务商",subname:""},
         
        ]},
        {name:"产品",icon:'nav3.png',lists:[
          {id:41,name:"智能制造先进装备",subname:"金奖/优秀奖"},
          {id:42,name:"技术工艺领先产品",subname:"金奖/优秀奖"},
          {id:43,name:"低碳节能先进技术",subname:"金奖/优秀奖"},
          {id:44,name:"绿色环保创新产品",subname:"金奖/优秀奖"}
        ]}
      ],
      list4:[
        {name:"企业",icon:'nav1.png',lists:[
          {id:45,name:"新锐榜20年",subname:"高质量发展企业"},
          {id:46,name:"新锐榜20年",subname:"卓越品牌"},
          {id:47,name:"新锐榜20年",subname:"品类发展引领者"},
          {id:48,name:"新锐榜20年",subname:"杰出原创IP产品奖"},
          {id:49,name:"新锐榜20年",subname:"经典产品成就奖"}
        ]}
      ]
    }
  },
  mounted(){
    // this.$store.dispatch('getPrizes');
    window.addEventListener("scroll",this.handleScroll); 
  },
  destroyed() {
      console.log("list destroyed");
      document.removeEventListener('scroll', this.handleScroll)
  },
  computed:{
    prizes(){return this.$store.state.prizes}
  },
  methods:{
    setIdx(idx){
      this.$store.commit('setPrizesIdx',idx);
      this.$router.push({ path: `/m_detail?idx=${idx}` }) 
    },
    scrollTo(e,top,idx){
    	// console.log(top);
    	this.idx = idx;
    	document.documentElement.scrollTop=top;
    	document.body.scrollTop=top;
    },
    handleScroll(){
    	let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    	// console.log(scrollTop);
      if(scrollTop<=310){
        this.idx = 0;
      }
      if(scrollTop>536&&scrollTop<=1000){
        this.idx = 1;
      }
      if(scrollTop>1000){
        this.idx = 2;
      }
  

    	if(scrollTop>298&&this.$refs.menu){
        this.$refs.menu.style.position="fixed";
        this.$refs.menu.style.top="0px";
    	}
      if(scrollTop<310&&this.$refs.menu){
        this.$refs.menu.style.position="absolute";
        this.$refs.menu.style.top="119px";
        // this.$refs.leftNav.style.position="absolute";
        // this.$refs.leftNav.style.top="160px";
    	}
    }
  }
}
</script>

<style scoped>
.nav{height:46px; text-align:center; background: #2a2925; font-size:14px; position: absolute; left:0;right:0; top:119px; display:flex; align-items: center;}
.nav>div{flex:0 0 33.3%; width:33.3%; height:46px; color:#b7b6b2; background: #2a2925; border-right:1px solid #403c37; display:flex;}
.nav>div>text{margin:auto;}
.nav>div:nth-of-type(3){border-right:1px solid #2a2925;}
.nav>div.che{background: #b50333; color:#FFF;}

.tips{font-size:13px; color:#edd393; padding:0px 14px; margin-top:31px; margin-bottom:35px;}


.left-nav{position: absolute; width:86px; background: #050b3f; left:0; top:160px;}
.left-nav li{height:61px; overflow:hidden; position:relative;}
.left-nav li.che{background: #b7002e;}
.left-nav li a{display: block; height:25px; line-height:25px; font-size:14px; margin-top:18px; border-left:4px solid #050b3f; color:#fff; text-align: center; color:#ffc15f;}
.left-nav li.che a{border-left:4px solid #fff; color:#fff;}
.left-nav li.che a::after{content:''; position: absolute; top:26px; right:4px; display: block; border-left:5px solid #fff; border-top:5px solid transparent; border-bottom:5px solid transparent;}

.nav-box{width:286px; margin:0 0 22px 0px; padding-top:0px;}
.nav-box .wrap{display:flex; width:100vw;}
.nav-box .typebox{flex:0 0 50px; height:50px; color:#fff; font-size:15px; text-align: center;}
.nav-box .typebox img{width:30px; height:30px; vertical-align: middle;}
.nav-box .typebox div{vertical-align: middle; margin-top:5px; font-size:12px;}
.nav-box .btnbox{flex:1 1 auto; display:flex; flex-wrap: wrap;}
.nav-box .btnbox .btn{flex:0 0 116px; width:116px; height:55px; background:#e7be86; color:#000; border-radius: 5px; margin-bottom:12px;
  display:flex; flex-direction: column; justify-content: center; align-items: center;  margin-right:5px;}
.nav-box .btnbox .btn:nth-of-type(3n){margin-right:0;}

.nav-box .btnbox .btn:hover{background:#b7002e; color:#fff;}
.nav-box .btnbox .btn>div:nth-of-type(1){font-size:12px; max-width: 125px;}
.nav-box .btnbox .btn>div:nth-of-type(2){font-size:12px;}

.nav-box .ntitle{width:414px; height:58px; margin:30px auto 20px auto; display:flex; background: url(../../assets/ntitle-bg.png) -60px center no-repeat; background-size:130% 130%;}
.nav-box .ntitle div{margin:auto; font-size:14px; color:#dcc189; font-weight: bold;}


.contact-box{width: 349px; height:68px; margin:30px auto 60px auto; border:2px solid #e0c08d; display:flex; align-items: center; color:#e0c08d; position:relative;}
.contact-box::after{content:''; display:block; position: absolute; top:76px; left:-2px; width:32px; height:7px; border:2px solid #e0c08d; display:flex;}
.contact-box>div:nth-of-type(1){font-size:14px; margin-left:20px; margin-right:72px;}
.contact-box>div:nth-of-type(2) div{padding:2px 0; font-size:13px;}

/*.about-xrb{color:#cf9560; padding:18px 20px 35px 20px;}
.about-xrb p{font-size:14px; line-height:25px;}
.about-xrb p>span{color:#fff;}
.about-xrb p.p1{margin-bottom:25px;}
.about-xrb p.under-line{text-decoration: underline;}
*/
/*.nav-box{width:100%; margin:0px 0 20px 106px; display:flex; flex-wrap: wrap; padding-top:55px;}
.nav-box div{display:flex; width:134px; height:55px; margin:0px 15px 12px 0; text-align:center; color:#5f3512; background:#eea95e; transition-duration: 300ms; border-radius:3px; font-size:15px; word-wrap: break-all; cursor:pointer;}
.nav-box div:nth-of-type(2n){margin-right:0;}
.nav-box div:hover{color:#fff; background:#b50007; box-shadow: 0 0 7px #000;}
.nav-box div>span{margin:auto;}*/

</style>