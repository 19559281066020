import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import '@/styles/common.css'

import store from './store/index.js'
import router from './router/index.js'

import './permission' 

// 全局指令
// import '@/directive/index.js' 
// 全局组件
// import '@/components/global/index.js'

import App from './App.vue'

// createApp(App).mount('#app')
const app = createApp(App)
app.use(ElementPlus)
app.use(store)
app.use(router)
app.mount('#app')


import Test from '@/components/global/Test.vue'
import Btn from '@/components/global/Btn.vue'
import Title from '@/components/global/Title.vue'
app.component('Test',Test);
app.component('Btn',Btn);
app.component('Title',Title);


export default app