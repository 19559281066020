<template>
  <div id="app">
    <HeaderBar v-if="!is_mobile"></HeaderBar>
    <HeaderMobile v-if="is_mobile"></HeaderMobile>
    <div class="content">
      <!-- <transition name="fade-transform" mode="out-in">
        <router-view></router-view>
      </transition> -->

      <router-view v-slot="{ Component }">
          <div>
              <transition name="fade-transform" mode="out-in">
                <component :is="Component" />
              </transition>
          </div>
      </router-view>

    </div>
    <Login></Login>
    <Zoom v-if="zoomVisible" :visible="zoomVisible"></Zoom>
  </div>
</template>

<script>
import Login from './components/Login.vue'
import HeaderBar from './components/HeaderBar.vue'
import HeaderMobile from './components/m-header.vue'

import Zoom from '@/components/zoom.vue';



export default {
  name: 'app',
  components: {
    HeaderBar,
    HeaderMobile,
    Login,
    Zoom
  },
  mounted() {
    if(this.isMobile()) {
      this.$router.replace('/m_index');
    }else{
      this.$router.replace('/index');
    }
  },
  computed:{
    zoomVisible(){
      return this.$store.state.zoomVisible
    },
    is_mobile(){return this.isMobile()}
  },
  methods:{
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
}
.content{width:865px; margin:0 auto; }

@media screen and (max-width: 414px) {
  .content{width:100%; position: relative;}
}
</style>

