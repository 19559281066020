import {getPrizes,getToken} from '@/api/index.js'

export default{
  getToken(context){
    getPrizes().then(res=>{
      console.log(res);
      context.commit('setPrizes',res.data.prizes_list);
    })
  },
  getPrizes(context){
    getPrizes().then(res=>{
      console.log(res);
      context.commit('setPrizes',res.data.prizes_list);
    })
  },
  login(context,params){
    return new Promise((resolve,reject)=>{
      getToken(params).then(res=>{
        console.log(res);
        
        // let token = 'zcRYcWIzobMkUSkBoLvB77386';
        // localStorage.token = token;
        // context.commit('setToken',token)
        // resolve(res);
        // reject(res);

        if(res.errcode===200){
          let token = res.data.token;
          localStorage.token = token;
          context.commit('setToken',token)
          resolve(res);
        }else{
          console.log('reject');
          reject(res);
        }

      })
    })
  }
}