<template>
  <div>
    <div class="tab-box">
      <div :class="{active:tabIdx===1}" @click="changeTab(1)">已报名奖项</div>
      <div :class="{active:tabIdx===0}" @click="changeTab(0)">草稿箱</div>
    </div>


    <div class="tabcon">

      <ul v-if="tabIdx===1">
        <li v-for="(item,index) in list1" :key="index">
          <div class="title ell">{{index+1}}. {{item.award_name}}</div>
          <div class="btnbox">
            <!-- <div class="btn" @click="edit(item.pid,item.award_id,1,1,item.id)">预览</div> -->
            <!-- <div class="btn" @click="edit(item.pid,item.award_id,0,1,item.id)">编辑</div> -->
            <div class="btn" @click="edit(item,1,1)">预览</div>
            <div class="btn" @click="edit(item,1,0)">编辑</div>

            <div class="btn" @click="del(item.prize_id)">删除</div>
          </div>
        </li>
        <li class="empty" v-if="list1.length==0">暂无数据</li>
      </ul>

      <ul v-if="tabIdx===0">
        <li v-for="(item,index) in list0" :key="index">
          <div class="title ell">{{index+1}}. {{item.award_name}}</div>
          <div class="btnbox">
            <!-- <div class="btn" @click="edit(item.pid,item.award_id,1,0,item.id)">预览</div> -->
            <!-- <div class="btn" @click="edit(item.pid,item.award_id,0,0,item.id)">编辑</div> -->
            <div class="btn" @click="edit(item,0,1)">预览</div>
            <div class="btn" @click="edit(item,0,0)">编辑</div>
            <div class="btn" @click="del(item.prize_id)">删除</div>
          </div>
        </li>
        <li class="empty" v-if="list0.length==0">暂无数据</li>
      </ul>

    </div>
    

  </div>
</template>

<script>
import {getPrizesList,delPrizes} from '@/api/index.js';
import store from '@/store/index.js';


export default {
  name: '',
  components: {},
  data(){
    return {
      tabIdx:1,
      lists:[]
    }
  },
  created(){
    console.log(this.$route.query)
    if(this.$route.query.tab==1){
      this.tabIdx=1;
    }
    this.getList();
  },
  computed:{
    token(){return this.$store.state.token;},
    list0(){return this.lists.filter(item=>item.status==='0')},
    list1(){return this.lists.filter(item=>item.status==='1')},
  },
  methods:{
    changeTab(idx){
      this.tabIdx=idx;
      this.getList();
    },
    getList(){
      getPrizesList({token:this.token,draft:this.tabIdx}).then(res=>{
        console.log(res);
        this.lists = res.data.prizes_list;
        // this['list'+this.tabIdx] = res.data.prizes_list;
      })
    },
    del(id){

      this.$confirm('删除不可恢复！是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        delPrizes({token:this.token,prize_id:id}).then(res=>{
          console.log(res);
          if(res.errcode===200){
            this.$message.success(res.errmsg);
            this.getList();
          }else{
            this.$message.error(res.errmsg);
          }
        })

      })
    },
    //表单id, 
    // edit(pid,prize_id,isPreview,draft,listid){
    //draft:1已报名的，0草稿箱
    edit(item,draft,isp){
       // this.$router.push({ path: `/edit?id=${pid}&prid=${prize_id}&isp=${isPreview}&d=${draft}&listid=${listid}` })
       // this.$router.push({ path: `/edit?id=${pid}&prid=${prize_id}&isp=${isPreview}&d=${draft}&listid=${listid}` })
       localStorage.setItem("formdata",JSON.stringify(item))
       this.$router.push({ path: '/edit', query: { idx: item.award_id,d:draft,isp:isp}})
    }
  }
}
</script>

<style scoped="">
.tab-box{display:flex; margin-top:44px;}
.tab-box div{width:165px; height:56px; line-height:56px; text-align:center; background:#b50333; color:#fff; cursor:pointer; font-size:14px; transition-duration: 300ms;}
.tab-box div.active{background:#d1ac75; color:#111; cursor:default; font-weight: bold; font-size:15px; line-height:60px;}

.tabcon{background:#d1ac75; padding:25px 25px; transition-duration: 300ms; position:relative; overflow:hidden;}
.tabcon li{display:flex; justify-content: space-between; align-items: center; padding-left:5px; height:70px; border-bottom:1px solid #6b3906;}
.tabcon li:nth-last-of-type(1){border:none;}
/*.tabcon li:hover{background:#f9f9f9;}*/
.tabcon li .title{flex:0 0 520px; width:520px; font-size:18px;}
.tabcon li .btnbox .btn{margin-left:5px;}

.btn{display:inline-block; width:80px; height:37px; line-height:37px; text-align:center; border:1px solid #b50333; color:#fff; background: #b50333; font-size:14px; border-radius:5px; cursor:pointer; transition-duration: 300ms;}
.btn:hover{background:#b50333; border:1px solid #b50333; color:#fff; box-shadow: 1px 2px 4px #666;}

.tabcon li.empty{text-align:center; color:#666; font-size:16px;display: block; line-height:70px;}
</style>