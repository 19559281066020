<template>
  <div class="login-box" :class="{show:show}">
    <div class="mask"></div>
    <div class="wrap">
      <img class="close" src="@/assets/close.jpg" alt="" @click="close">
      <div class="input-box">
        <input type="text" placeholder="手机号" v-model="form.mobile">
      </div>
      <div class="input-box code">
        <input type="text" placeholder="验证码" v-model="form.sms_code">
        <div class="getcode" @click="getCode()" v-if="!countDown">获取验证码</div>
        <div class="time" v-if="countDown">{{time}} s</div>
      </div>

      <div class="login" @click="login">登 录</div>
    </div>
  </div>
</template>

<script>
import {getCode} from '@/api/index.js'
//77386 token zcRYcWIzobMkUSkBoLvB77386
export default {
  name: 'login',
  props: {
    msg: String
  },
  data(){
    return {
      time:60,
      countDown:false,
      form:{
        mobile:'',
        sms_code:''
      }
    }
  },
  computed:{
    token(){return this.$store.state.token},
    show(){return this.$store.state.loginShow}
  },
  methods:{
    login(){
      if(this.form.mobile===''){
        this.$message.error('请填写手机号！');
        return false;
      }
      if(this.form.sms_code===''){
        this.$message.error('请填写验证码！');
        return false;
      }
      const loading = this.$loading({
          lock: true,
          text: '登录中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
      });

      this.$store.dispatch('login',this.form)
      .then((res)=>{
        console.log(res);
        loading.close();
        this.$message.success('登录成功！');
        this.$store.commit('setLoginShow',false);
      },(err)=>{
        loading.close();
        this.$message.error(err.errmsg);
      })
    },
    getCode(){
      if(this.form.mobile===''){
        this.$message.error('请填写手机号！');
        return false;
      }

      let _this = this;
      const loading = this.$loading({
          lock: true,
          text: '获取中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
      });

      function countDown(){
        _this.countDown=true;
        if(_this.time===0){
          clearTimeout(_this.sto);
          _this.countDown = false;
          _this.time=60;
        }else{
          _this.time--
          _this.sto = setTimeout(countDown,1000)
        }
      }

      getCode({mobile:this.form.mobile}).then(res=>{
        if(res.errcode===200){
          countDown();
          this.$message.success(res.errmsg);
        }else{
          this.$message.error(res.errmsg);
        }
        loading.close();
      })
    },
    close(){
      this.$store.commit('setLoginShow',false);
    }
  }
}
</script>

<style scoped>
.login-box{position:fixed; top:0; bottom:0; right:0; left:0; display:flex; visibility: hidden; user-select: none;}
.login-box .mask{position:absolute; z-index:10; top:0; bottom:0; right:0; left:0; background:rgba(0,0,0,0); transition-duration: 300ms;}
.login-box .wrap{position: relative;z-index:11; width:320px; height:390px; margin:auto; background: url(../assets/login-bg.png) no-repeat; position: relative; transform: scale(.8); opacity:0; transition-duration: 300ms;}
.login-box .wrap .close{display:none; position: absolute; top:163px; left:280px; cursor:pointer; transition-duration: 600ms;}
.login-box .wrap .close:hover{transform: rotate(180deg);}

.login-box .input-box{margin:215px 0 0 27px; width:268px; height:36px;}
.login-box .input-box input{width:268px; height:36px; text-indent:10px; border:none;outline:none; background:none;}
.login-box .code{margin:12px 0 0 27px; width:268px; height:37px; display:flex; user-select: none;}
.login-box .code input{flex:0 0 150px; width:150px;height:36px; text-indent:10px; border:none;outline:none; background:none;}
.login-box .code .getcode,.login-box .code .time
{flex:1 1 auto; height:36px; line-height:36px; text-align:center; color:#b50333; cursor:pointer;}
.login-box .code .time{color:#b50333;}

.login-box .login{width:270px; height:40px; line-height:40px; background: #b50333; font-size:15px; margin:12px 0 0 27px; color:#fff; text-align:center; cursor:pointer; transition-duration: 300ms;}
.login-box .login:hover{box-shadow: 1px 1px 3px #999;}

.login-box.show{visibility: visible; }
.login-box.show .mask{background:rgba(0,0,0,.8);}
.login-box.show .wrap{transform: scale(1); opacity:1;}
.login-box.show .wrap .close{display:block;}

</style>

<style>
  .el-loading-spinner i,.el-loading-spinner .el-loading-text{color:#b50333!important;}
</style>