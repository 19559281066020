<template>
    <div class="">
      <!-- <label class="label" for="name">{{title}}</label> -->
      <div class="ibox">
        <textarea cols="30" rows="10" class="textarea" 
          v-if="!isPreview"
          :name="name"
          :placeholder="placeholder" 
          :value="modelValue" 
          @input="$emit('update:modelValue', $event.target.value)">
        </textarea>
        <div class="textarea" v-if="isPreview"><pre>{{modelValue}}</pre></div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ctextarea',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: String,
    placeholder:String,
    // value:String,
    name:String,
    isPreview:{type:Boolean,default:false}
  }
}
</script>

<style scoped>
/*.label{line-height:36px; border-bottom:1px solid #ccc; display: block; margin:0 15px; font-size:14px; color:#333; font-weight:bold; }*/
.ibox{flex:1 1 auto; display:flex; align-items:center;}
.ibox .textarea{flex:1 1 auto; margin:0px 15px 10px 15px; padding:0px 5px; height:100px; outline:none; border:none; resize:none;transition-duration: 300ms; line-height:18px;}
.ibox div.textarea{height:auto; }
.ibox:hover textarea{background:#f9f9f9;}
pre{
white-space: pre-wrap!important;
word-wrap: break-word!important;
*white-space:normal!important;
}
</style>
