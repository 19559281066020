export default{
  setToken(state,d){
    state.token = d
  },
  setPrizes(state,d){
    state.prizes = d
  },
  setPrizesIdx(state,d){
    state.prizesIdx = d
  },
  setLoginShow(state,d){
    state.loginShow = d
  },
  setZoomVisible: (state, b) => {
    state.zoomVisible = b;
  },
  setZoomList:(state, arr)=>{
    state.zoomList = arr;
  },
  setZoomIdx:(state,idx)=>{
    state.zoomIdx=idx;
  }
}