<template>
  <div class="test">
    {{title}}
  </div>
</template>

<script>
  export default{
    name: 'test',
    props: {
      title:String
    }
  }
</script>

<style scoped>
  .test{width:153px; height:44px; line-height:44px; border:1px solid #ff9b4b; color:#ff9b4b; font-size:18px; text-align:center; cursor:pointer; transition-duration: 300ms; border-radius:5px; user-select: none;}
  .test:hover{background:#b50007; border:1px solid #b50007; color:#fff; box-shadow: 1px 2px 3px #000;}
</style>